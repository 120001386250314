import React from "react";

function Earnabout() {
  return (
    <div className="earnabout">
      {/* <!-- Breadcrumb Banner --> */}
      <section class="breadcrumb-banner">
        <div>
          <h1>About Us</h1>
          <div class="breadcrumb">
            <a href="#">Home</a> &gt; <span>About Us</span>
          </div>
        </div>
      </section>

      {/* <!-- About Section --> */}
      <section class="about-section">
        <img src="/img/OIG29.jpg" alt="About Us Main Image" />
        <div class="about-content">
          <h2 class="animated-heading">Welcome to Dhanwan.online</h2>
          <h1>
            डॉ. सागर मल्होत्रा एक बहुमुखी और प्रेरणादायक व्यक्तित्व हैं,
            जिन्होंने विभिन्न क्षेत्रों में अपनी अनूठी पहचान स्थापित की है। वे
            नवीनतम तकनीकों और विचारों के माध्यम से नई संभावनाओं का निर्माण करते
            हुए एक विशेष स्थान बना चुके हैं। प्रॉपर्टी और रियल एस्टेट क्षेत्र
            में उनके अनुभव और दूरदृष्टि ने उन्हें इस उद्योग का एक प्रभावशाली नाम
            बनाया है। अंतर्राष्ट्रीय व्यापार के क्षेत्र में भी उन्होंने विभिन्न
            देशों के बीच व्यापारिक संबंधों को मजबूत किया है। डॉ. मल्होत्रा का
            मानना है कि हमारे साथ जुड़ने वाले प्रत्येक व्यक्ति को और अधिक सशक्त
            किया जाए। इस सशक्तिकरण के माध्यम से वे चाहते हैं कि हर व्यक्ति अपनी
            आर्थिक स्थिति को बेहतर बनाए और अपनी जरूरतों को आसानी से पूरा कर सके।
            उनका लक्ष्य है कि उनके साथ जुड़े हर व्यक्ति और उनके नेटवर्क में
            शामिल अन्य लोग भी आर्थिक रूप से मजबूत बनें। यह दृष्टिकोण न केवल समाज
            के विकास में सहायक है, बल्कि व्यक्तिगत और सामूहिक सशक्तिकरण का मार्ग
            भी प्रशस्त करता है।
            <br></br>
            (CEO OF DHANWAN.ONLINE)
            <p>Inventor and Real Estate Developer</p>
          </h1>
        </div>
      </section>

      {/* <!-- Profile Section --> */}
      <section class="profile-section">
        {/* <!-- MD Profile --> */}
        {/* <div class="profile-card">
        <img src="/img/OIG29.jpg" alt="MD"/>
        <h3>John Doe</h3>
        <p>Managing Director</p>
        <p>With over a decade of experience in the industry, John leads with innovation and vision to take our platform to new heights.</p>
    </div> */}

        {/* <!-- CEO Profile --> */}
        {/* <div class="profile-card">
        <img src="/img/OIG13.jpg" alt="CEO"/>
        <h3>Jane Smith</h3>
        <p>Chief Executive Officer</p>
        <p>A dynamic leader, Jane specializes in strategy and partnerships, ensuring a seamless affiliate experience for our users.</p>
    </div> */}
      </section>
    </div>
  );
}

export default Earnabout;
