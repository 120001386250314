import React from "react";
import { GiftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const WelcomeBox = () => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //   height: '100vh',
      //   backgroundColor: '#f5f5f5',
    },
    card: {
      width: "300px",
      padding: "20px",
      textAlign: "center",
      background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
      color: "#fff",
      borderRadius: "12px",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
    },
    icon: {
      fontSize: "48px",
      color: "#fff",
      marginBottom: "20px",
    },
    title: {
      fontSize: "24px",
      margin: "10px 0",
      fontWeight: "bold",
      color: "#fff",
    },
    text: {
      fontSize: "16px",
      margin: "20px 0",
      color: "#fff",
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#1890ff",
      border: "none",
      borderRadius: "20px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#40a9ff",
    },
  };

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  let pageUrll =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&clubsuper=left";
  let pageUrl2r =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&clubsuper=right";

  function copysup() {
    const el = document.createElement("input");
    el.value = pageUrll;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }
  function copy2sup() {
    const el = document.createElement("input");
    el.value = pageUrl2r;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <GiftOutlined style={styles.icon} />
        <h2 style={styles.title}>Welcome to Dhanwan.online</h2>
        <p style={styles.text}>
        🌟 Welcome to the journey of success Achieve big income, fulfill dreams, and enjoy life with our support. 🚀 Best wishes
        </p>
        {/* <button

        onClick={copysup}
          style={styles.button}
          onMouseOver={(e) =>
            (e.target.style.backgroundColor =
              styles.buttonHover.backgroundColor)
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = styles.button.backgroundColor)
          }
        >
          Refer For Left
        </button>
        <button
         onClick={copy2sup}
          style={styles.button}
          onMouseOver={(e) =>
            (e.target.style.backgroundColor =
              styles.buttonHover.backgroundColor)
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = styles.button.backgroundColor)
          }
        >
          Refer For Right
        </button> */}
      </div>
    </div>
  );
};

export default WelcomeBox;
