import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
// import HeadingBack from "../../HeadingBack";

function LostAndFoundCom() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  //   console.log(navigate);
  //   console.log(navigate?.query?.page);
  //   console.log(searchParams);
  //   console.log(page);
  //   console.log(searchData);

  const order_list = useSelector((state) =>
    state?.allapi?.get_user_lost_and_found_List
      ? state?.allapi?.get_user_lost_and_found_List
      : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getuserlostandfoundnew(page));
    }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deletelostandfound({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {/* {item?.user_id?.fullName} */}
              <img
                src={URL?.API_BASE_URL + item?.image}
                alt=""
                style={{ width: "70px" }}
              />
              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Category name",
      dataIndex: "subcategoryId",
      key: "subcategoryId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.subcategoryId}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Category name",
      dataIndex: "subsubcategoryId",
      key: "subsubcategoryId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.subsubcategoryId}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           <p> {item?.user_id?.referByuserId?.referId}</p>
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.color}
            </span>
          </>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "city",
      key: "city",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.city},{item?.state},{item?.country},{item?.pincode}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
                <span
                  className="cursor-pointer"
                  onClick={() => navigate(`/lost-and-found/${item?.slug}`)}
                >
                  View
                </span>
              </Tooltip> */}
            <Tooltip title="View">
              <button
                className="filter-button ml-3 mt-3"
                onClick={() => navigate(`/lost-and-found/${item?.slug}`)}
              >
                View
              </button>
            </Tooltip>
            <Tooltip title="Delete">
              <button
                className="filter-button ml-3 mt-3"
                onClick={() => showModal(item?._id)}
              >
                Delete
              </button>
            </Tooltip>
            {/* <Tooltip title="Invoice">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Invoice
              </button>
            </Tooltip>
            <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/lost-and-found?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="Delete Lost And Found"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Inovice"
        centered
        open={statusDataBox}
        // onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div
          id="kt_app_content"
          className="app-content flex-column-fluid"
          ref={componentRef}
        >
          {/* -begin::Content container-*/}
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            {/* - begin::Invoice 3-*/}
            <div className="">
              {/* - begin::Body-*/}
              <div className="card-body py-20">
                {/* - begin::Wrapper-*/}
                <div className="mw-lg-950px mx-auto w-100">
                  {/* - begin::Header-*/}

                  <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                    <div>
                      <h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-7">
                        INVOICE
                      </h4>
                      {/* -end::Logo-*/}
                      <div className="fw-bold fs-2 ">
                        Dear, {order_details?.address_id?.name}
                        {/* <span className='fs-6'>(f.mit@kpmg.com)</span>, */}
                        <br />
                        <span className="text-muted  companyName">
                          Email : {order_details?.address_id?.email}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          Number : {order_details?.address_id?.phone}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          Address : {order_details?.address_id?.address}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          City : {order_details?.address_id?.city}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          State : {order_details?.address_id?.state}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          Pincode : {order_details?.address_id?.pincode}
                        </span>
                        <span className="text-muted  companyName">
                          {/* Here are your order details. We thank you for your purchase. */}
                          {/* sd */}
                        </span>
                      </div>
                    </div>

                    <div className="text-sm-end">
                      {/* -begin::Logo-*/}
                      <a
                        href="#"
                        style={{ width: "150px" }}
                        className="d-block mw-150px ms-sm-auto"
                      >
                        <img
                          alt="Logo"
                          src={URL.API_BASE_URL + get_invoice?.invoiceLogo}
                          className="w-100"
                        />
                      </a>
                      {/* -end::Logo-*/}
                      {/* -begin::Text-*/}
                      <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                        <div className="companyName">
                          {get_invoice?.companyName}
                        </div>
                        <div className="companyName">{get_invoice?.email}</div>
                        <div className="companyName">
                          {get_invoice?.address}
                        </div>
                        {/* <div>Mississippi 96522</div> */}
                      </div>
                      {/* -end::Text-*/}
                    </div>
                  </div>
                  {/* -end::Header-*/}
                  {/* -begin::Body-*/}
                  <div className="pb-12">
                    {/* -begin::Wrapper-*/}
                    <div className="d-flex flex-column gap-7 gap-md-10">
                      {/* -begin::Message-*/}
                      <div className="fw-bold fs-2">
                        Dear, {order_details?.address_id?.name}
                        {/* <span className='fs-6'>(f.mit@kpmg.com)</span>, */}
                        <br />
                        <span className="text-muted fs-5">
                          {/* Here are your order details. We thank you for your purchase. */}
                        </span>
                      </div>
                      {/* -begin::Message-*/}
                      {/* -begin::Separator-*/}
                      <div className="separator"></div>
                      {/* -begin::Separator-*/}
                      {/* -begin::Order details-*/}
                      <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                        <div className="flex-root d-flex col-6 flex-column">
                          <span className="text-muted">Order ID</span>
                          <span className="fs-5">#{productID?._id}</span>
                          <span className="text-muted">Transation ID</span>
                          <span className="fs-5">
                            #{productID?.transationId}
                          </span>
                          <span className="text-muted">Paid Status</span>
                          <span className="fs-5">{productID?.paidstatus}</span>
                        </div>
                        <div className="flex-root d-flex col-6 flex-column">
                          <span className="text-muted">Date</span>
                          <span className="fs-5">
                            {moment(productID?.createdAt).format(
                              " MMMM Do YYYY"
                            )}
                          </span>
                        </div>
                        {/* <div className='flex-root d-flex flex-column'>
                                  <span className='text-muted'>Invoice ID</span>
                                  <span className='fs-5'>#INV-000414</span>
                                </div>
                                <div className='flex-root d-flex flex-column'>
                                  <span className='text-muted'>Shipment ID</span>
                                  <span className='fs-5'>#SHP-0025410</span>
                                </div> */}
                      </div>
                      {/* -end::Order details-*/}
                      {/* -begin::Billing & shipping-*/}
                      {/* <div className='d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold'>
                                <div className='flex-root d-flex flex-column'>
                                  <span className='text-muted'>Billing Address</span>
                                  <span className='fs-6'>
                                    Unit 1/23 Hastings Road,
                                    <br />
                                    Melbourne 3000,
                                    <br />
                                    Victoria,
                                    <br />
                                    Australia.
                                  </span>
                                </div>
                                <div className='flex-root d-flex flex-column'>
                                  <span className='text-muted'>Shipping Address</span>
                                  <span className='fs-6'>
                                    Unit 1/23 Hastings Road,
                                    <br />
                                    Melbourne 3000,
                                    <br />
                                    Victoria,
                                    <br />
                                    Australia.
                                  </span>
                                </div>
                              </div> */}
                      {/* -end::Billing & shipping-*/}
                      {/* -begin:Order summary-*/}
                      <div className="d-flex justify-content-between flex-column">
                        {/* -begin::Table-*/}
                        <div className="table-responsive border-bottom mb-9">
                          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                            <thead>
                              <tr className="border-bottom fs-6 fw-bold text-muted">
                                <th>S.No</th>
                                <th className="min-w-175px pb-2">Item</th>
                                <th className="min-w-70px text-end pb-2">
                                  Color
                                </th>
                                <th className="min-w-70px text-end pb-2">
                                  Size
                                </th>
                                <th className="min-w-70px text-end pb-2">
                                  Unit Cost
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Quantity
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Net Amount(₹)
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Tax(₹)
                                </th>

                                <th className="min-w-100px text-end pb-2">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className="fw-semibold invotb text-gray-600">
                              {/* -begin::Products-*/}

                              {/* {order_details?.orderproductsList &&
                                order_details?.orderproductsList?.map(
                                  (data, i) => {
                                    console.log(data);

                                    return ( */}
                              <tr>
                                {/* -begin::Product-*/}
                                <td className="">1</td>
                                <td>
                                  <div className="d-flex ">
                                    <div className="">
                                      <div className="fw-bold">
                                        {order_details?.product_names}
                                      </div>
                                      <div className="fs-7 text-muted">
                                        {/* Delivery Date: 23/11/2022 */}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {/* -end::Product-*/}
                                {/* -begin::SKU-*/}
                                <td className="text-end">
                                  {" "}
                                  {order_details?.colorId?.color}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.sizeId?.size}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {/* {order_details?.product_price} */}
                                  {basePrice?.toFixed(2)}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {basePrice?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>
                                {/* -end::SKU-*/}
                                {/* -begin::Quantity-*/}
                                <td className="text-end">
                                  {" "}
                                  {perProgst?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>

                                {/* -end::Quantity-*/}
                                {/* -begin::Total-*/}
                                <td className="text-end">
                                  ₹ {order_details?.sub_total}
                                </td>
                                {/* -end::Total-*/}
                              </tr>
                              {/* );
                                  }
                                )} */}

                              {/* -end::Products-*/}
                              {/* -begin::Subtotal-*/}
                              {/* <tr>
                                <td colSpan={8} className="text-end">
                                  Subtotal
                                </td>
                                <td className="text-end">
                                  ₹ {order_details?.sub_total}
                                </td>
                              </tr> */}
                              <tr>
                                <td colSpan={8} className="text-end">
                                  Shipping
                                </td>
                                <td className="text-end">₹ {shippingCharge}</td>
                              </tr>
                              {/* <tr>
                                <td colSpan={8} className="text-end">
                                  Gst
                                </td>
                                <td className="text-end">
                                  ₹ {totalSubtotalPricegstdd}
                                </td>
                              </tr> */}

                              <tr>
                                <td
                                  colSpan={8}
                                  className="fs-3 text-dark fw-bold text-end"
                                >
                                  Grand Total
                                </td>
                                <td className="text-dark fs-3 fw-bolder text-end">
                                  ₹ {totalAmount}
                                </td>
                              </tr>
                              {/* -end::Grand total-*/}
                            </tbody>
                          </table>
                        </div>
                        {/* -end::Table-*/}
                      </div>
                      {/* -end:Order summary-*/}
                    </div>
                    {/* -end::Wrapper-*/}
                  </div>
                  {/* -end::Body-*/}
                  {/* - begin::Footer-*/}
                  {/* <div className='d-flex flex-stack flex-wrap mt-lg-20 pt-13'>
                            
                            <div className='my-1 me-5'>
                           
                              <button
                                type='button'
                                className='btn btn-success my-1 me-12'
                                // onclick='window.print();'
                              >
                                Print Invoice
                              </button>
                          
                              <button type='button' className='btn btn-light-success my-1'>
                                Download
                              </button>
                            
                            </div>
                           
                            <a
                              href='../../demo1/dist/apps/invoices/create.html'
                              className='btn btn-primary my-1'
                            >
                              Create Invoice
                            </a>
                           
                          </div> */}
                  {/* - end::Footer-*/}
                </div>
                {/* - end::Wrapper-*/}
              </div>
              {/* - end::Body-*/}
            </div>
            {/* - end::Invoice 1-*/}
          </div>
          {/* -end::Content container-*/}
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
                // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
                //   Print / Download
                // </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            {/* <h6>Name</h6>
                    <p>{roleID?.name}</p> */}
            {/* <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div> */}
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            {/* {order_details?.orderproductsList &&
              order_details?.orderproductsList?.map((data, i) => {
                return ( */}
            <div>
              <div className="row">
                <div className="col-3">
                  <h6>sub order id</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sub_order_id}</p>
                </div>
                <div className="col-3">
                  <h6>name</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.product_names}</p>
                </div>
                <div className="col-3">
                  <h6>Color</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.colorId?.color}</p>
                </div>
                <div className="col-3">
                  <h6>size</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sizeId?.size}</p>
                </div>

                <div className="col-3">
                  <h6>product price</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.product_price}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>item Count</h6>
                </div>
                <div className="col-3">
                  <p>x {order_details?.itme_count}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>sub total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.sub_total}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>Shipping</h6>
                </div>
                <div className="col-3">
                  <p>₹ {shippingCharge}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Gst</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalSubtotalPricegstdd}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Grant total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalAmount}</p>
                </div>
                <div className="col-3">
                  <h6>feture image</h6>
                </div>
                <div className="col-3">
                  <div style={{ width: "100px" }}>
                    <img
                      src={
                        URL.API_BASE_URL + order_details?.colorId?.featureImage
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* <p>  {data?.sub_total}</p> */}
                </div>
              </div>

              <hr />
            </div>
            {/* );
              })} */}

            {/* <h6>product Image</h6> */}
            {/* <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div> */}
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Lost And Found"}
            subHeading={`Total ${order_list?.totalDocs || 0} Lost And Found`}
          />
        </div>
        {/* <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
        {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          />  
        </Col> */}

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              // onClick={() => navigate("/add-lost-found")}
              onClick={() => {
                // navigate("/add-lost-found")
                window.location.href = "/add-lost-found";
              }}
            >
              Add
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default LostAndFoundCom;
