import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../../HeadingBack";
import { useLocation, useNavigate } from "react-router-dom";
import { URL } from "../../../Redux/common/url";
import { allapiAction } from "../../../Redux/common/action";
import { toast } from "react-toastify";

function PointRequestCom() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const order_list = useSelector((state) =>
    state?.allapi?.get_withdrawal_requset_list
      ? state?.allapi?.get_withdrawal_requset_list
      : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    // } else {
    dispatch(allapiAction.getPointListusers(page));

    // }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);
  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    // } else {
    dispatch(allapiAction.getInvoice());

    // }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, []);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.amount}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Resion",
      dataIndex: "resion",
      key: "resion",
    },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <Tooltip title="Invoice">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Invoice
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="View">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewProduct(item)}
    //           >
    //             View
    //           </button>
    //         </Tooltip>

    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/accounts/point-requset?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/point-requset?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [amount, setAmount] = useState("");
  const [userProfile1, setUserProfile1] = useState({});
  const handleProfileData1 = (e) => {
    const { name, value } = e.target;
    setUserProfile1({
      ...userProfile1,
      [name]: value,
    });
  };
  const submitProductWithdrawal = async () => {
    // const data = new FormData();
    // if (images[0]?.file) {
    // roledata?.role_name
    // data.append("logo", images[0]?.file);

    if (!userProfile1.amount) {
      toast.error("Please enter amount");
    } else if (!userProfile1.transationId) {
      toast.error("Please enter utr no.");
    } else {
      //   const response = await dispatch(
      //     allapiAction.Withdrawalsrequset({ amount: amount })
      //   );
      const response = await dispatch(allapiAction.addPointreq(userProfile1));
      if (response?.success) {
        dispatch(allapiAction.getPointListusers(page));
        setAmount("");
        // }
        setShow2(false);
        // dispatch(allapiAction.vendorpaybleamdall());
      }
    }
  };
  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Wallet"
        open={show2}
        onOk={submitProductWithdrawal}
        onCancel={handleClose2}
      >
        <div className="card mx-auto ">
          <div className="card-body">
            <div className="card-body">
              <div className="mb-3">
                <img
                  src={URL?.API_BASE_URL + get_invoice?.homeLogo}
                  style={{ width: "100%" }}
                />
              </div>
              {get_invoice?.upi && (
                <div className="mb-3">Upi Id : {get_invoice?.upi}</div>
              )}
              {get_invoice?.ac && (
                <div className="mb-3">Ac : {get_invoice?.ac}</div>
              )}
              {get_invoice?.ifsc && (
                <div className="mb-3">IFSC : {get_invoice?.ifsc}</div>
              )}
              {get_invoice?.achname && (
                <div className="mb-3">Ac H. Name : {get_invoice?.achname}</div>
              )}
              <div className="mb-3">
                <label className="form-label">Amount</label>
                <input
                  name="amount"
                  className="form-control"
                  placeholder="Enter Amount"
                  type="text"
                  value={userProfile1.amount}
                  onChange={handleProfileData1}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">UTR No.</label>
                <input
                  name="transationId"
                  className="form-control"
                  placeholder="Enter UTR"
                  type="text"
                  value={userProfile1.transationId}
                  onChange={handleProfileData1}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Point Request"}
            subHeading={`Total ${order_list?.totalDocs || 0} Point Request`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => handleShow2("")}>
              Add Wallet
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default PointRequestCom;
