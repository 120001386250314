import Boxes2 from "./Boxes2";
import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../../Redux/common/action";
import Frorders from "./Frorders";
import FrOrderForUsers from "./FrOrderForUsers";
// import { allapiAction } from "@/Redux/common/action";

function Billing() {
  const dispatch = useDispatch();
  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  console.log(deshboard);
  useEffect(() => {
    dispatch(allapiAction.vendordeshBoradData({}));
    return () => {};
  }, []);
  const data = get_category_sale;
  console.log(get_category_sale);
  return (
    <div>
      

      <main id="content" role="main" class="main pointer-event dash">
        {/* <!-- Content --> */}
        <div class="content container-fluid">
          {/* <!-- Page Header --> */}
          <div class="page-header pb-0 mb-0 border-0">
            <div class="flex-between align-items-center">
              <div>
                <h1 className="page-header-title" style={{ textAlign: "left" }}>
                  Billing
                </h1>
                {/* <p>Welcome message.</p> */}
              </div>
            </div>
          </div>
          
          {/* <Frorders /> */}
          <FrOrderForUsers/>
        

       
       
        </div>

     
 

        
      </main>
    </div>
  );
}

export default Billing;
