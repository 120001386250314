import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
// import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import SvgIcon from "../const/SvgIcon";
// import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
// import { URL } from "../../Redux/common/url";
import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";

// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
// import Image from "next/image";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Image } from "react-bootstrap";
import { URL } from "../../../Redux/common/url";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";

function ProductLsitFr() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_product_list ? state?.allapi?.get_product_list : {}
  );

  const get_product_details = useSelector((state) =>
    state.allapi.get_product_details ? state.allapi.get_product_details : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    const objs = { page: page };

    if (search) {
      objs.key = search;
    }
    dispatch(allapiAction.getProductListfr(objs));
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
      },
      onCancel() {},
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    dispatch(allapiAction.productDetails(e));
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleToStatusfuture = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.futuredProduct({ productId: id, status: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{ width: "100px" }}>
              <img
                style={{ width: "100%" }}
                src={URL?.API_BASE_URL + item?.productcolorId?.featureImage}
                alt=""
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productId?.title}
            </span>
          </>
        );
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productcolorId?.color}
            </span>
          </>
        );
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productsizeId?.size}
            </span>
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productsizeId?.price}
            </span>
          </>
        );
      },
    },
    {
      title: "After Disacount Price",
      dataIndex: "afterdiscountprice",
      key: "afterdiscountprice",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productsizeId?.afterdiscountprice}
            </span>
          </>
        );
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productsizeId?.gst}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.productcolorId?.color}
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    // },
    // {
    //   title: "After Discount Price",
    //   dataIndex: "afterdiscountprice",
    //   key: "afterdiscountprice",
    // },
    // {
    //   title: "GST",
    //   dataIndex: "gst",
    //   key: "gst",
    // },
    {
      title: "stock",
      dataIndex: "stock",
      key: "stock",
    },

    // {
    //   title: "Futured",
    //   dataIndex: "futured",
    //   key: "futured",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.futured}>
    //           <button
    //             className={
    //               item?.futured == "Yes"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatusfuture(
    //                 item?._id,
    //                 item?.futured == "Yes" ? "No" : "Yes"
    //               )
    //             }
    //           >
    //             {item?.futured}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             // onClick={() =>
    //             //   hendleToStatus(
    //             //     item?._id,
    //             //     item?.status == "Active" ? "Inactive" : "Active"
    //             //   )
    //             // }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    // {
    //   title: "Join Date",
    //   dataIndex: "Join Date",
    //   key: "Join Date",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.createdAt).format(" DD/MM/YYYY ")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title="Edit">
    //           <span
    //             className="cursor-pointer"
    //             onClick={
    //               () =>
    //                 navigate(`/seller/product/update-product/${item?.pro_sulg}`)
    //               // (window.location.href = `/seller/product/update-product/${item?.pro_sulg}`)
    //             }
    //           >
    //             <Image
    //               src={SvgIcon?.Pencil}
    //               alt="Left Arrow"
    //               width={20}
    //               height={20}
    //               style={{ verticalAlign: "middle" }}
    //             />
    //             {/* <SvgIcon.Pencil className="scale-icon edit-icon" /> */}
    //           </span>
    //         </Tooltip>
    //         {/* <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?.id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip> */}
    //         <Tooltip title="View">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item?.pro_sulg)}
    //           >
    //             View
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="Delete">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //               showDeleteConfirm(item?._id);
    //             }}
    //           >
    //             <Image
    //               src={SvgIcon?.deletebtn}
    //               alt="Left Arrow"
    //               width={20}
    //               height={20}
    //               style={{ verticalAlign: "middle" }}
    //             />
    //             {/* <SvgIcon.deletebtn className="scale-icon edit-icon" /> */}
    //           </span>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/franchies/product?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/franchies/product?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Product Details"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={1000}
      >
        <div>
          <Row>
            <Col span={4}>Name</Col>
            <Col span={8}>{get_product_details?.title}</Col>
            <Col span={4}>Seo Title</Col>
            <Col span={8}>{get_product_details?.seo_title}</Col>
            <Col span={4}>Seo Description</Col>
            <Col span={8}>{get_product_details?.seo_description}</Col>
            <Col span={4}>Price</Col>
            <Col span={8}>{get_product_details?.price}</Col>
            <Col span={4}>Afer Discount Price</Col>
            <Col span={8}>{get_product_details?.afterdiscountprice}</Col>

            <Col span={24}>
              <h3>Variant</h3>
            </Col>
            {get_product_details?.subcolor &&
              get_product_details?.subcolor?.map((data, i) => {
                return (
                  <Col span={24} key={i}>
                    {/* <h6>{data?.color}</h6> */}
                    <Row>
                      <Col span={4}>Color</Col>
                      <Col span={8}>{data?.color}</Col>
                      <Col span={4}>Image</Col>
                      <Col span={8}>
                        <div className="row">
                          {data?.images &&
                            data?.images?.map((Dataimg, indexim) => {
                              return (
                                <div style={{ width: "60px" }} key={indexim}>
                                  <img
                                    src={URL?.API_BASE_URL + Dataimg?.img}
                                    alt=""
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>

                    {data?.productsize &&
                      data?.productsize?.map((data2, i) => {
                        return (
                          <Row key={i}>
                            <Col span={2}>Size</Col>
                            <Col span={2}>{data2?.size}</Col>
                            <Col span={2}>price</Col>
                            <Col span={2}>{data2?.price}</Col>
                            <Col span={4}>after discount price</Col>
                            <Col span={2}>{data2?.afterdiscountprice}</Col>
                            <Col span={2}>stock</Col>
                            <Col span={2}>{data2?.stock}</Col>
                          </Row>
                        );
                      })}

                    <hr />
                  </Col>
                );
              })}
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Product"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => {
                navigate("/seller/product/add-product");
                // window.location.href = "/seller/product/add-product";
              }}
            >
              Add
              
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          rowClassName={(record) => {
            // Apply different classes based on conditions
            if (record.stock < 1) {
              return "antrow-active"; // Class for active rows
            } else if (record.status === "inactive") {
              return "antrow-inactive"; // Class for inactive rows
            }
            return "";
          }}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />
      </div>
    </div>
  );
}

export default ProductLsitFr;
