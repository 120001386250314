 

import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";

import TopBar from "../Layout3/TopBar";
import Sidebar from "../Layout3/Sidebar";
import LostAndFoundCom from "./LostAndFoundCom";
import TotalTeamComponent from "./TotalTeamComponent";
import PvLsitComponent from "./PvLsitComponent";
const { useBreakpoint } = Grid;
// import OrderCom from "./OrderCom";

// import SaleComp from "../../../../Component/Seller/SaleCom/SaleCom";
const { Header, Content, Footer } = Layout;
function PvList() {
  const [collapsed, setCollapsed] = useState(false);
  const screens = useBreakpoint();
 
  console.log("screens------------------------------", !screens.md);
  console.log("screens------------------------------", screens);

  useEffect(() => {
    setCollapsed(!screens.md);
    return () => {};
  }, [screens]);
  return (
    <div>
      <Layout className="layout_css">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header className="site-layout-background1" style={{ padding: 0 }}>
            <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "6px",
              padding: 10,
              minHeight: 280,
              overflowY: "auto",
            }}
          >
            <PvLsitComponent />
          </Content>
          {/* <Footer></Footer> */}
        </Layout>
      </Layout>
    </div>
  );
}

export default PvList;
