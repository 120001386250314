import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import ReactToPrint from "react-to-print";
// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../../HeadingBack";
import { URL } from "../../../Redux/common/url";
// import { allapiAction } from "../../../Redux/common/action";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";

function SaleComfr({ pagetype }) {
  const pageofget =
    pagetype == "canceled"
      ? "Canceled Sale"
      : pagetype == "confirmed"
        ? "Confirmed Sale"
        : pagetype == "delivered"
          ? "Delivered Sale"
          : pagetype == "out-for-delivery"
            ? "Out for delivery Sale"
            : pagetype == "pending"
              ? "Pending Sale"
              : pagetype == "processing"
                ? "Processing Sale"
                : pagetype == "returned"
                  ? "Returned Sale"
                  : "All Sale";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useParams();
  const componentRef = useRef();
  const { confirm } = Modal;
  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  const order_list = useSelector((state) =>
    state?.allapi?.vendor_order_list ? state?.allapi?.vendor_order_list : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const [pages, setPage] = useState(1);
  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    // if (pagetype) {
      if (pagetype == "main") {
        dispatch(
          allapiAction.getSearchOrderfranchiesself({
            page: page,
            key: search,
            status: "",
          })
        );
      } else {
        dispatch(
          allapiAction.getSearchOrderfranchiesself({
            page: page,
            key: search,
            status: pagetype,
          })
        );
      }
    // } else {
    //   dispatch(allapiAction.getuserOrdervendor(page));
    // }
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;
  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)
  const basePrice = totalPrice / (1 + gstRate / 100);
  const basePriceTotal = basePrice * order_details?.itme_count;
  const perProgst = totalPrice - basePrice;
  const totalgst = order_details?.sub_total - basePriceTotal;
  const totalSubtotalPricegst = totalSubtotalPricegstdd;
  const totalAmount = order_details?.sub_total + shippingCharge;
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    /*  dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        pageNumber: page,
      })
    ); */
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Sell Code",
      dataIndex: "sellCode",
      key: "sellCode",
      className: "table_action_col",
    },
    {
      title: "Customer Info",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span className="cursor-pointer coustomNevc">
              {item?.user_id?.fullName}
            </span>
            <a class="d-block title-color" href="tel:7231818743">
              {item?.user_id?.phone}
            </a>
          </>
        );
      },
    },
    // {
    //   title: "Buyer Number",
    //   dataIndex: "phone",
    //   key: "phone",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span className="cursor-pointer ">{item?.user_id?.phone}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span className="cursor-pointer">
              {moment(item?.createdAt).format(" DD/MM/YYYY hh:mm A")}
            </span>
          </>
        );
      },
    },
    {
      title: "Total Amount",
      dataIndex: "sub_total",
      key: "sub_total",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span className="cursor-pointer">₹ {item?.sub_total}</span>
          </>
        );
      },
    },
    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    {
      title: "Order Status",
      dataIndex: "delivery_status",
      key: "delivery_status",
    },
    {
      title: "Order return",
      dataIndex: "orderretune",
      key: "orderretune",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Invoice">
              <span className="cursor-pointer  ">
                {item?.orderretune == "true" ? "Yes" : "No"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Invoice">
              <sapn class="btn btn-outline-success square-btn btn-sm mr-1">
                Invoice
              </sapn>
            </Tooltip> */}

            <Tooltip title="Invoice">
              <span
                className="cursor-pointer btn btn-outline-primary mr-5"
                onClick={() => hendleToViewM(item)}
              >
                Invoice
              </span>
            </Tooltip>

            <Tooltip title="View">
              <span
                className="cursor-pointer btn btn-outline-success"
                // onClick={() => hendleToViewM(item)}
                onClick={() => hendleToViewProduct(item)}
              >
                <img
                  src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                  class="svg"
                  alt=""
                />
              </span>
            </Tooltip>
            {/* <Tooltip title="View">
              <sapn
                class="btn btn-outline--primary square-btn btn-sm mr-1"
                onClick={() => hendleToViewProduct(item)}
              >
                <img
                  src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                  class="svg"
                  alt=""
                />
              </sapn>
            </Tooltip> */}
            {/* <Tooltip title="Return Order"></Tooltip> */}
          </>
        );
      },
    },
  ];
  const pageofget2 =
    pagetype == "canceled"
      ? "/canceled"
      : pagetype == "confirmed"
        ? "/confirmed"
        : pagetype == "delivered"
          ? "/delivered"
          : pagetype == "out-for-delivery"
            ? "/out-for-delivery"
            : pagetype == "pending"
              ? "/pending"
              : pagetype == "processing"
                ? "/processing"
                : pagetype == "returned"
                  ? "/returned"
                  : "";
  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale" + pageofget2 + "?page=" + 1 + "&search=" + e);
  };

  const hendlePahegi = (e) => {
    setPage(e);
    navigate(
      "/seller/sale" + pageofget2 + "?page=" + e + "&&search=" + search
    );
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Inovice"
        centered
        open={statusDataBox}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div
          id="kt_app_content"
          className="app-content flex-column-fluid"
          ref={componentRef}
        >
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            <div className="">
              <div className="card-body py-20">
                <div className="mw-lg-950px mx-auto w-100">
                  <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                    <div>
                      <h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-7">
                        INVOICE
                      </h4>
                      <div className="fw-bold fs-2 ">
                        Dear, {order_details?.address_id?.name}
                        <br />
                        <span className="text-muted  companyName">
                          Email : {order_details?.address_id?.email}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          Number : {order_details?.address_id?.phone}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          Address : {order_details?.address_id?.address}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          City : {order_details?.address_id?.city}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          State : {order_details?.address_id?.state}
                        </span>
                        <br />
                        <span className="text-muted  companyName">
                          Pincode : {order_details?.address_id?.pincode}
                        </span>
                        <span className="text-muted  companyName"></span>
                      </div>
                    </div>

                    <div className="text-sm-end">
                      <a
                        href="#"
                        style={{ width: "150px" }}
                        className="d-block mw-150px ms-sm-auto"
                      >
                        <div className="ps-logo">
                          <span className="gradient-color-text">
                            BestBharat
                          </span>
                        </div>
                      </a>
                      <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                        <div className="companyName">
                          {get_invoice?.companyName}
                        </div>
                        <div className="companyName">{get_invoice?.email}</div>
                        <div className="companyName">
                          {get_invoice?.address}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pb-12">
                    <div className="d-flex flex-column gap-7 gap-md-10">
                      <div className="fw-bold fs-2">
                        Dear, {order_details?.address_id?.name}
                        <br />
                        <span className="text-muted fs-5"></span>
                      </div>
                      <div className="separator"></div>
                      <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                        <div className="flex-root d-flex col-6 flex-column">
                          <span className="text-muted">Order ID</span>
                          <span className="fs-5">#{productID?._id}</span>
                          <span className="text-muted">Transation ID</span>
                          <span className="fs-5">
                            #{productID?.transationId}
                          </span>
                          <span className="text-muted">Paid Status</span>
                          <span className="fs-5">{productID?.paidstatus}</span>
                        </div>
                        <div className="flex-root d-flex col-6 flex-column">
                          <span className="text-muted">Date</span>
                          <span className="fs-5">
                            {moment(productID?.createdAt).format(
                              " MMMM Do YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-column">
                        <div className="table-responsive border-bottom mb-9">
                          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                            <thead>
                              <tr className="border-bottom fs-6 fw-bold text-muted">
                                <th>S.No</th>
                                <th className="min-w-175px pb-2">Item</th>
                                <th className="min-w-70px text-end pb-2">
                                  Color
                                </th>
                                <th className="min-w-70px text-end pb-2">
                                  Size
                                </th>
                                <th className="min-w-70px text-end pb-2">
                                  Unit Cost
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Quantity
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Net Amount(₹)
                                </th>
                                <th className="min-w-80px text-end pb-2">
                                  Tax(₹)
                                </th>

                                <th className="min-w-100px text-end pb-2">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className="fw-semibold invotb text-gray-600">
                              <tr>
                                <td className="">1</td>
                                <td>
                                  <div className="d-flex ">
                                    <div className="">
                                      <div className="fw-bold">
                                        {order_details?.product_names}
                                      </div>
                                      <div className="fs-7 text-muted"></div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.colorId?.color}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.sizeId?.size}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {basePrice?.toFixed(2)}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {basePrice?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {perProgst?.toFixed(2) *
                                    order_details?.itme_count}
                                </td>
                                <td className="text-end">
                                  ₹ {order_details?.sub_total}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className="text-end">
                                  Shipping
                                </td>
                                <td className="text-end">₹ {shippingCharge}</td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={8}
                                  className="fs-3 text-dark fw-bold text-end"
                                >
                                  Grand Total
                                </td>
                                <td className="text-dark fs-3 fw-bolder text-end">
                                  ₹ {totalAmount}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            <div>
              <div className="row">
                <div className="col-3">
                  <h6>sub order id</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sub_order_id}</p>
                </div>
                <div className="col-3">
                  <h6>name</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.product_names}</p>
                </div>
                <div className="col-3">
                  <h6>Color</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.colorId?.color}</p>
                </div>
                <div className="col-3">
                  <h6>size</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sizeId?.size}</p>
                </div>

                <div className="col-3">
                  <h6>product price</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.product_price}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>item Count</h6>
                </div>
                <div className="col-3">
                  <p>x {order_details?.itme_count}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>sub total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.sub_total}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>Shipping</h6>
                </div>
                <div className="col-3">
                  <p>₹ {shippingCharge}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Gst</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalSubtotalPricegstdd}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Grant total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalAmount}</p>
                </div>
                <div className="col-3">
                  <h6>feture image</h6>
                </div>
                <div className="col-3">
                  <div style={{ width: "100px" }}>
                    <img
                      src={
                        URL.API_BASE_URL + order_details?.colorId?.featureImage
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={pageofget}
            subHeading={`Total ${order_list?.totalDocs || 0} Sale`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
          />
        </Col>
      </div>

      <main id="content" role="main" class="main pointer-event">
        {/* <!-- Content --> */}
        <div class="content container-fluid">
          {/* <!-- Page Header --> */}
          <div>
            {/* <!-- Page Title --> */}
            {/* <div class="d-flex flex-wrap gap-2 align-items-center mb-3">
              <h2 class="h1 mb-0">
                <img
                  src="https://ganeshji.online/public/assets/back-end/img/all-orders.png"
                  class="mb-1 mr-1"
                  alt=""
                />
                <span class="page-header-title">All</span>
                Orders
              </h2>
              <span class="badge badge-soft-dark radius-50 fz-14">9</span>
            </div> */}
            {/* <!-- End Page Title --> */}

            {/* <!-- Order States --> */}
            <div class="card">
              <div class="card">
                <div class="card-body">
                  <form
                    action="#"
                    id="form-data"
                    method="GET"
                  >
                    <div class="row gy-3 gx-2">
                      <div class="col-12 pb-0">
                        <h4>Select Date Range</h4>
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <select name="filter" class="form-control">
                          <option value="all">All</option>
                          <option value="admin">In House</option>
                          <option value="seller">Seller</option>
                          <option value="POS">POS</option>
                        </select>
                      </div>
                      <div class="col-sm-6 col-md-3">
                        <div class="form-floating">
                          <input
                            type="date"
                            name="from"
                            value=""
                            id="from_date"
                            class="form-control"
                          />
                          <label>Start Date</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 mt-2 mt-sm-0">
                        <div class="form-floating">
                          <input
                            type="date"
                            value=""
                            name="to"
                            id="to_date"
                            class="form-control"
                          />
                          <label>End Date</label>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 mt-2 mt-sm-0  ">
                        <button
                          type="submit"
                          class="btn btn--primary btn-block"
                          onclick="formUrlChange(this)"
                          data-action="#"
                        >
                          Show Data
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="card-body">
                {/* <!-- Order stats --> */}
                <div class="row g-2 mb-20">
                  <div class="col-sm-6 col-lg-3 mt-15">
                    {/* <!-- Card --> */}
                    <a
                      class="order-stats order-stats_pending"
                      href="#"
                    >
                      <div class="order-stats__content">
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/pending.png"
                          class="svg"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Pending</h6>
                      </div>
                      <span class="order-stats__title">5</span>
                    </a>
                    {/* <!-- End Card --> */}
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    {/* <!-- Card --> */}
                    <a
                      class="order-stats order-stats_confirmed"
                      href="#"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/confirmed.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Confirmed</h6>
                      </div>
                      <span class="order-stats__title">0</span>
                    </a>
                    {/* <!-- End Card --> */}
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    {/* <!-- Card --> */}
                    <a
                      class="order-stats order-stats_packaging"
                      href="#"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/packaging.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Packaging</h6>
                      </div>
                      <span class="order-stats__title">0</span>
                    </a>
                    {/* <!-- End Card --> */}
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    {/* <!-- Card --> */}
                    <a
                      class="order-stats order-stats_out-for-delivery"
                      href="#"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/out-of-delivery.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Out for delivery</h6>
                      </div>
                      <span class="order-stats__title">0</span>
                    </a>
                    {/* <!-- End Card --> */}
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    <div
                      class="order-stats order-stats_delivered cursor-pointer"
                      // onclick="#"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/delivered.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Delivered</h6>
                      </div>
                      <span class="order-stats__title">4</span>
                    </div>
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    <div
                      class="order-stats order-stats_canceled cursor-pointer"
                      // onclick="location.href='https://ganeshji.online/admin/orders/list/canceled'"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/canceled.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Canceled</h6>
                      </div>
                      <span class="order-stats__title">0</span>
                    </div>
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    <div
                      class="order-stats order-stats_returned cursor-pointer"
                      // onclick="location.href='https://ganeshji.online/admin/orders/list/returned'"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/returned.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">Returned</h6>
                      </div>
                      <span class="order-stats__title">0</span>
                    </div>
                  </div>

                  <div class="col-sm-6 col-lg-3 mt-15">
                    <div
                      class="order-stats order-stats_failed cursor-pointer"
                      // onclick="location.href='https://ganeshji.online/admin/orders/list/failed'"
                    >
                      <div
                        class="order-stats__content"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          width="20"
                          src="https://ganeshji.online/public/assets/back-end/img/failed-to-deliver.png"
                          alt=""
                        />
                        <h6 class="order-stats__subtitle">
                          Failed To Delivery
                        </h6>
                      </div>
                      <span class="order-stats__title">0</span>
                    </div>
                  </div>
                </div>
                {/* <!-- End Order stats --> */}

                {/* <!-- Data Table Top --> */}
                <div class="px-3 py-4 light-bg">
                  <div class="row g-2 flex-grow-1">
                    <div class="col-sm-8 col-md-6 col-lg-4">
                      <form action="" method="GET">
                        {/* <!-- Search --> */}
                        <div class="input-group input-group-custom input-group-merge">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i class="tio-search"></i>
                            </div>
                          </div>
                          <input
                            id="datatableSearch_"
                            type="search"
                            name="search"
                            class="form-control"
                            placeholder="Search by Order ID"
                            aria-label="Search by Order ID"
                            value=""
                            required
                          />
                          <button
                            type="submit"
                            class="btn btn--primary input-group-text"
                          >
                            Search
                          </button>
                        </div>
                        {/* <!-- End Search --> */}
                      </form>
                    </div>
                    <div class="col-sm-4 col-md-6 col-lg-8 d-flex justify-content-sm-end">
                      <button
                        type="button"
                        class="btn btn-outline--primary"
                        data-toggle="dropdown"
                      >
                        <i class="tio-download-to"></i>
                        Export
                        <i class="tio-chevron-down"></i>
                      </button>

                      <ul class="dropdown-menu dropdown-menu-right">
                        <li>
                          <a
                            type="submit"
                            class="dropdown-item d-flex align-items-center gap-2"
                            href="#"
                          >
                            <img
                              width="14"
                              src="https://ganeshji.online/public/assets/back-end/img/excel.png"
                              alt=""
                            />
                            Excel
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Row --> */}
                </div>
                {/* <!-- End Data Table Top --> */}

                <div className="tableResponsive mt-10">
                  <Table
                    className={"table antd_table"}
                    dataSource={listdata}
                    columns={columns}
                    pagination={{
                      current: parseInt(page),
                      pageSize: 10,
                      onChange(current, pageSize) {
                        hendlePahegi(current);
                      },
                      hideOnSinglePage: true,
                      total: order_list?.totalDocs ? order_list?.totalDocs : 0,
                    }}
                  />
                </div>

                {/* <!-- Table --> */}
                {/* <div class="table-responsive datatable-custom">
                  <table
                    class="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                    style={{ textAlign: "left" }}
                  >
                    <thead class="thead-light thead-50 text-capitalize">
                      <tr>
                        <th class="">SL</th>
                        <th>Order ID</th>
                        <th>Order Date</th>
                        <th>Customer Info</th>
                        <th>Store</th>
                        <th class="text-right">Total Amount</th>
                        <th class="text-center">Order Status </th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="status-pending class-all">
                        <td class="">1</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100009"
                          >
                            100009
                          </a>
                        </td>
                        <td>
                          <div>21 May 2024,</div>
                          <div>10:36 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100009"
                          >
                            <strong class="title-name">admin sharma</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7231818743">
                            7231818743
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹1,835.20</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100009"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100009"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">2</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100008"
                          >
                            100008
                          </a>
                        </td>
                        <td>
                          <div>04 Oct 2023,</div>
                          <div>09:07 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100008"
                          >
                            <strong class="title-name">ram singh</strong>
                          </a>
                          <a
                            class="d-block title-color"
                            href="tel:917976065962"
                          >
                            917976065962
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹906.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100008"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100008"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">3</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100007"
                          >
                            100007
                          </a>
                        </td>
                        <td>
                          <div>07 Sep 2023,</div>
                          <div>04:08 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100007"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹899.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100007"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100007"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">4</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100006"
                          >
                            100006
                          </a>
                        </td>
                        <td>
                          <div>07 Sep 2023,</div>
                          <div>04:08 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100006"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100006"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100006"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">5</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100005"
                          >
                            100005
                          </a>
                        </td>
                        <td>
                          <div>04 Sep 2023,</div>
                          <div>01:18 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100005"
                          >
                            <strong class="title-name">test test</strong>
                          </a>
                          <a class="d-block title-color" href="tel:8767657667">
                            8767657667
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100005"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100005"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">6</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100004"
                          >
                            100004
                          </a>
                        </td>
                        <td>
                          <div>03 Sep 2023,</div>
                          <div>04:15 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100004"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100004"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100004"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">7</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100003"
                          >
                            100003
                          </a>
                        </td>
                        <td>
                          <div>03 Sep 2023,</div>
                          <div>03:07 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100003"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100003"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100003"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-delivered class-all">
                        <td class="">8</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100002"
                          >
                            100002
                          </a>
                        </td>
                        <td>
                          <div>03 Sep 2023,</div>
                          <div>02:54 PM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100002"
                          >
                            <strong class="title-name">Nipun Agarwal</strong>
                          </a>
                          <a class="d-block title-color" href="tel:7733878649">
                            7733878649
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            Golden Computers
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹900.00</div>

                          <span class="badge text-success fz-12 px-0">
                            Paid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-success fz-12">
                            Delivered
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100002"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100002"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr class="status-pending class-all">
                        <td class="">9</td>
                        <td>
                          <a
                            class="title-color"
                            href="https://ganeshji.online/admin/orders/details/100001"
                          >
                            100001
                          </a>
                        </td>
                        <td>
                          <div>26 Aug 2023,</div>
                          <div>09:48 AM</div>
                        </td>
                        <td>
                          <a
                            class="text-body text-capitalize"
                            href="https://ganeshji.online/admin/orders/details/100001"
                          >
                            <strong class="title-name">vijay2 2</strong>
                          </a>
                          <a class="d-block title-color" href="tel:9999999999">
                            9999999999
                          </a>
                        </td>
                        <td>
                          <span class="store-name font-weight-medium">
                            In-House
                          </span>
                        </td>
                        <td class="text-right">
                          <div>₹538.00</div>

                          <span class="badge text-danger fz-12 px-0">
                            Unpaid
                          </span>
                        </td>
                        <td class="text-center text-capitalize">
                          <span class="badge badge-soft-info fz-12">
                            Pending
                          </span>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center gap-2">
                            <a
                              class="btn btn-outline--primary square-btn btn-sm mr-1"
                              title="View"
                              href="https://ganeshji.online/admin/orders/details/100001"
                            >
                              <img
                                src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                                class="svg"
                                alt=""
                              />
                            </a>
                            <a
                              class="btn btn-outline-success square-btn btn-sm mr-1"
                              target="_blank"
                              title="Invoice"
                              href="https://ganeshji.online/admin/orders/generate-invoice/100001"
                            >
                              <i class="tio-download-to"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                {/* <!-- End Table --> */}

                {/* <!-- Pagination --> */}
                <div class="table-responsive mt-4">
                  <div class="d-flex justify-content-lg-end">
                    {/* <!-- Pagination --> */}
                  </div>
                </div>
                {/* <!-- End Pagination --> */}
              </div>
            </div>
            {/* <!-- End Order States --> */}

            {/* <!-- Nav Scroller --> */}
            <div class="js-nav-scroller hs-nav-scroller-horizontal d-none">
              <span class="hs-nav-scroller-arrow-prev d-none">
                <a class="hs-nav-scroller-arrow-link" href="javascript:;">
                  <i class="tio-chevron-left"></i>
                </a>
              </span>

              <span class="hs-nav-scroller-arrow-next d-none">
                <a class="hs-nav-scroller-arrow-link" href="javascript:;">
                  <i class="tio-chevron-right"></i>
                </a>
              </span>

              {/* <!-- Nav --> */}
              <ul class="nav nav-tabs page-header-tabs">
                <li class="nav-item">
                  <a class="nav-link active" href="#">
                    Order list
                  </a>
                </li>
              </ul>
              {/* <!-- End Nav --> */}
            </div>
            {/* <!-- End Nav Scroller --> */}
          </div>
          {/* <!-- End Page Header --> */}
        </div>
        {/* <!-- End Content --> */}

        {/* <!-- Footer --> */}
        {/* <div class="footer">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-4 mb-3 mb-lg-0">
              <p class="font-size-sm mb-0 title-color text-center text-lg-left">
                &copy; Shop.{" "}
                <span class="d-none d-sm-inline-block">
                  CopyRight 6amTech@2021
                </span>
              </p>
            </div>
            <div class="col-lg-8">
              <div class="d-flex justify-content-center justify-content-lg-end">
              
                <ul class="list-inline list-footer-icon justify-content-center justify-content-lg-start mb-0">
                  <li class="list-inline-item">
                    <a
                      class="list-separator-link"
                      href="https://ganeshji.online/admin/business-settings/web-config"
                    >
                      <i class="tio-settings"></i>
                      Business Setup
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <a
                      class="list-separator-link"
                      href="https://ganeshji.online/admin/profile/update/1"
                    >
                      <i class="tio-user"></i>
                      Profile
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <a
                      class="list-separator-link"
                      href="https://ganeshji.online/admin/dashboard"
                    >
                      <i class="tio-home-outlined"></i>
                      Home
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <label class="badge badge-soft-version text-capitalize">
                      Software version 13.1
                    </label>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div> */}

        <div
          class="modal fade"
          id="logoutModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Ready to Leave?
                </h5>
                <button
                  class="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                Select Logout below if you are ready to end your current
                session.
              </div>
              <div class="modal-footer">
                <form
                  action="#"
                  method="post"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="S0xp0RrM5s22igEsAwCiv5L8DNffxUtpxmWA6hvQ"
                  />{" "}
                  <button
                    class="btn btn-danger"
                    type="button"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button class="btn btn--primary" type="submit">
                    Logout
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" id="popup-modal">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <center>
                      <h2 class="__color-8a8a8a">
                        <i class="tio-shopping-cart-outlined"></i> You have new
                        order, Check Please.
                      </h2>
                      <hr />
                      <button onclick="check_order()" class="btn btn--primary">
                        Ok, let me check
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SaleComfr;
