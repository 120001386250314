import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import Sidebar from "../LayoutL/Sidebar";
import TopBar from "../LayoutL/TopBar";
import Deshboard from "./Deshboard/Deshboard";
import Billing from "./Deshboard/Billing";
import BuyProduct from "./Deshboard/BuyProduct";
 
const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;
function BuyScreen() {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(!screens.md);
    return () => {};
  }, [screens]);
  return (
    <div>
      <Layout className="layout_css">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header className="site-layout-backgroundr" style={{ padding: 0 }}>
            <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "6px",
              padding: 10,
              minHeight: 280,
              overflowY: "auto",
            }}
          >
            <BuyProduct />
          </Content>
          {/* <Footer></Footer> */}
        </Layout>
      </Layout>
    </div>
  );
}

export default BuyScreen;
