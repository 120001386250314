import React from "react";

function Earnplan() {
  return (
    <div class="earnplan">
      <div class="custom-heading">
        <h1>Our Plan</h1>
      </div>

      <div class="single-image-container">
        <div class="image-card">
          <img src="/img/111.jpg" alt="Plan Image 1" />
        </div>
        <div class="image-card">
          <img src="/img/ppt1.jpg" alt="Plan Image 2" />
        </div>
        <div class="image-card">
          <img src="/img/ppt2.jpg" alt="Plan Image 3" />
        </div>
        <div class="image-card">
          <img src="/img/ppt3.jpg" alt="Plan Image 4" />
        </div>
        <div class="image-card">
          <img src="/img/ppt4.jpg" alt="Plan Image 5" />
        </div>
        <div class="image-card">
          <img src="/img/ppt5.jpg" alt="Plan Image 6" />
        </div>
        <div class="image-card">
          <img src="/img/ppt6.jpg" alt="Plan Image 7" />
        </div>
        <div class="image-card">
          <img src="/img/ppt7.jpg" alt="Plan Image 8" />
        </div>
        <div class="image-card">
          <img src="/img/ppt8.jpg" alt="Plan Image 9" />
        </div>
        <div class="image-card">
          <img src="/img/ppt9.jpg" alt="Plan Image 10" />
        </div>
        <div class="image-card">
          <img src="/img/ppt10.jpg" alt="Plan Image 10" />
        </div>
        <div class="image-card">
          <img src="/img/ppt17.jpg" alt="Plan Image 10" />
        </div>
        <div class="image-card">
          <img src="/img/ppt11.jpg" alt="Plan Image 10" />
        </div>
        <div class="image-card">
          <img src="/img/ppt12.jpg" alt="Plan Image 10" />
        </div>
        <div class="image-card">
          <img src="/img/ppt13.jpg" alt="Plan Image 10" />
        </div>
        {/* <div class="image-card">
          <img src="/img/ppt14.jpg" alt="Plan Image 10" />
        </div> */}
        <div class="image-card">
          <img src="/img/ppt15.jpg" alt="Plan Image 10" />
        </div>
        <div class="image-card">
          <img src="/img/ppt16.jpg" alt="Plan Image 10" />
        </div>
      </div>
    </div>
  );
}

export default Earnplan;
