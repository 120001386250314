import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";
import Tree from "react-d3-tree";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import Api from "../../Redux/common/api";
import { URL } from "../../Redux/common/url";
import MixedNodeElement from "./MixedNodeElement";
// import HeadingBack from "../../HeadingBack";

function GenologyComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  //   console.log(navigate);
  //   console.log(navigate?.query?.page);
  //   console.log(searchParams);
  //   console.log(page);
  //   console.log(searchData);

  const order_list = useSelector((state) =>
    state?.allapi?.my_direct_team ? state?.allapi?.my_direct_team : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    } else {
      dispatch(allapiAction.sellerDirectTeam(page));
    }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.getusercertifitedelete({ _id: e, page: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.getusercertifitedelete({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {/* {item?.user_id?.fullName} */}
              <img
                src={URL?.API_BASE_URL + item?.image}
                alt=""
                style={{ width: "70px" }}
              />
              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Certificate No.",
      dataIndex: "srno",
      key: "srno",
      className: "table_action_col",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
    },

    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Certificate for ",
      dataIndex: "crType",
      key: "crType",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.crType}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Father",
      dataIndex: "fatherName",
      key: "fatherName",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.fatherName}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           <p> {item?.user_id?.referByuserId?.referId}</p>
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.status}
            </span>
          </>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "city",
      key: "city",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.city},{item?.state},{item?.pincode}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?._id)}
              >
                Delete
              </button>
            </Tooltip>
            <Tooltip title="Invoice">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Print
              </button>
            </Tooltip>
            {/* <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/lost-and-found?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const [treeData, setTreeData] = useState(null);
  const [treeData1, setTreeData1] = useState(null);

  useEffect(() => {
    fetchDownlineHierarchy();
  }, []);

  const fetchDownlineHierarchy = async (userId) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await Api.get(`${URL.genelogybyuser}`, config);

      //       createdAt
      // :
      // "2020-03-31T18:30:00.000Z"
      // fullName
      // :
      // "Chitranjan tomar Resolved Improve Life"
      // referBy
      // :
      // ""
      // referId
      // :
      // "RIL00001"
      // status
      // :
      // "4"
      // _id
      // :
      // "6567c5ba5532b0536f80174a"

      const margeList = [
        {
          _id: response?.data?._id,
          status: response?.data?.status,
          referId: response?.data?.referId,
          referBy: response?.data?.referBy,
          fullName: response?.data?.fullName,
          createdAt: response?.data?.createdAt,
        },
        ...response?.data?.children,
      ];
      const margeList2 =
        margeList &&
        margeList?.map((data) => {
          const finaldata = data;
          const status = data?.status;
          const statusdata =
            status == 0
              ? "/assets/imgs/fc.png"
              : status == 1
                ? "/assets/imgs/fr.png"
                : status == 2
                  ? "/assets/imgs/br.png"
                  : status == 3
                    ? "/assets/imgs/ct.png"
                    : status == 4
                      ? "/assets/imgs/mq.png"
                      : status == 5
                        ? "/assets/imgs/duke.png"
                        : "/assets/imgs/duke.png";
          finaldata.id = data?.referId;
          finaldata.pid = data?.referBy;
          finaldata.img = statusdata;
          finaldata.tags = ["Management"];

          return finaldata;
        });
      // tags: ["Management"]
      // const response = await axios.get(`/api/users/${userId}/downline`);
      margeList2.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setTreeData(response.data);
    } catch (error) {
      console.error("Error fetching downline hierarchy:", error);
    }
  };
  const fetchDownlineHierarchy2 = async (userId) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await Api.post(`${URL.genelogybyuserTeam}`,{_id :userId}, config);

      //       createdAt
      // :
      // "2020-03-31T18:30:00.000Z"
      // fullName
      // :
      // "Chitranjan tomar Resolved Improve Life"
      // referBy
      // :
      // ""
      // referId
      // :
      // "RIL00001"
      // status
      // :
      // "4"
      // _id
      // :
      // "6567c5ba5532b0536f80174a"

      const margeList = [
        {
          _id: response?.data?._id,
          status: response?.data?.status,
          referId: response?.data?.referId,
          referBy: response?.data?.referBy,
          fullName: response?.data?.fullName,
          createdAt: response?.data?.createdAt,
        },
        ...response?.data?.children,
      ];
      const margeList2 =
        margeList &&
        margeList?.map((data) => {
          const finaldata = data;
          const status = data?.status;
          const statusdata =
            status == 0
              ? "/assets/imgs/fc.png"
              : status == 1
                ? "/assets/imgs/fr.png"
                : status == 2
                  ? "/assets/imgs/br.png"
                  : status == 3
                    ? "/assets/imgs/ct.png"
                    : status == 4
                      ? "/assets/imgs/mq.png"
                      : status == 5
                        ? "/assets/imgs/duke.png"
                        : "/assets/imgs/duke.png";
          finaldata.id = data?.referId;
          finaldata.pid = data?.referBy;
          finaldata.img = statusdata;
          finaldata.tags = ["Management"];

          return finaldata;
        });
      // tags: ["Management"]
      // const response = await axios.get(`/api/users/${userId}/downline`);
      margeList2.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setTreeData(response.data);
    } catch (error) {
      console.error("Error fetching downline hierarchy:", error);
    }
  };

  const CustomNodeComponent = ({ nodeData }) => {
    const { name, attributes } = nodeData;
    const { email, imageUrl } = attributes;

    return (
      <div style={{ textAlign: "center", width: "150px", height: "100px" }}>
        <img
          src={imageUrl}
          alt={name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <div>{name}</div>
        <div>{email}</div>
      </div>
    );
  };

  const data = [
    {
      id: 1,
      firstName: "Terry",
      lastName: "Medhurst",
      maidenName: "Smitham",
      age: 50,
      gender: "male",
      referBy: "0",
    },
    {
      id: 2,
      firstName: "Sheldon",
      lastName: "Quigley",
      maidenName: "Cole",
      age: 28,
      gender: "male",
      referBy: "1",
    },
    {
      id: 3,
      firstName: "Terrill",
      lastName: "Hills",
      maidenName: "Hoeger",
      age: 38,
      gender: "male",
      referBy: "1",
    },
    {
      id: 4,
      firstName: "Miles",
      lastName: "Cummerata",
      maidenName: "Maggio",
      age: 49,
      gender: "male",
      referBy: "2",
    },
    {
      id: 5,
      firstName: "Mavis",
      lastName: "Schultz",
      maidenName: "Yundt",
      age: 38,
      gender: "male",
      referBy: "2",
    },
    {
      id: 6,
      firstName: "Alison",
      lastName: "Reichert",
      maidenName: "Franecki",
      age: 21,
      gender: "female",
      referBy: "4",
    },
    {
      id: 7,
      firstName: "Oleta",
      lastName: "Abbott",
      maidenName: "Wyman",
      age: 31,
      gender: "female",
      referBy: "3",
    },
    {
      id: 8,
      firstName: "Ewell",
      lastName: "Mueller",
      maidenName: "Durgan",
      age: 29,
      gender: "male",
      referBy: "4",
    },
    {
      id: 9,
      firstName: "Demetrius",
      lastName: "Corkery",
      maidenName: "Gleason",
      age: 22,
      gender: "male",
      referBy: "6",
    },
    {
      id: 10,
      firstName: "Eleanora",
      lastName: "Price",
      maidenName: "Cummings",
      age: 37,
      gender: "female",
      referBy: "8",
    },
    {
      id: 11,
      firstName: "Marcel",
      lastName: "Jones",
      maidenName: "Smith",
      age: 39,
      gender: "male",
      referBy: "9",
    },
    {
      id: 12,
      firstName: "Assunta",
      lastName: "Rath",
      maidenName: "Heller",
      age: 42,
      gender: "female",
      referBy: "7",
    },
    {
      id: 13,
      firstName: "Trace",
      lastName: "Douglas",
      maidenName: "Lemke",
      age: 26,
      gender: "male",
      referBy: "12",
    },
    {
      id: 14,
      firstName: "Enoch",
      lastName: "Lynch",
      maidenName: "Heidenreich",
      age: 21,
      gender: "male",
      referBy: "11",
    },
  ];

  const hierarchy = {
    name: "Root", // You can set any root name you like
    children: [],
  };

  const idToNode = {};
  data.forEach((item) => {
    idToNode[item.id] = {
      name: `${item.firstName} ${item.lastName}`,
      attributes: { age: item.age, gender: item.gender },
      parent: item.referBy === "0" ? hierarchy : idToNode[item.referBy],
      children: [],
    };

    if (item.referBy !== "0") {
      idToNode[item.referBy].children.push(idToNode[item.id]);
    }
  });

  const generateTreeData = (data, parentId) => {
    // console.log(data);
    const children = data.filter((item) => item.placmentbyId == parentId);
    children.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    const left = children.filter((item) => item.position == "L");
    const right = children.filter((item) => item.position == "R");
    const leftLength = left.length;
    const rightLength = right.length;
    const leftPlaceholder = leftLength < rightLength ? 1 : 0;
    const rightPlaceholder = rightLength < leftLength ? 1 : 0;
    return [
      ...left.map((child, index) => {
        const status = child.status;
        // console.log(status);/
        const statusdata =
          status == 0
            ? "fc"
            : status == 1
              ? "fr"
              : status == 2
                ? "br"
                : status == 3
                  ? "ct"
                  : status == 4
                    ? "mq"
                    : status == 5
                      ? "duke"
                      : "";
        // console.log(statusdata);
        const statusdata2 =
          status == 0
            ? "/assets/imgs/genealogy.png"
            : status == 1
              ? "/assets/imgs/genealogy.png"
              : status == 2
                ? "/assets/imgs/genealogy.png"
                : status == 3
                  ? "/assets/imgs/genealogy.png"
                  : status == 4
                    ? "/assets/imgs/genealogy.png"
                    : status == 5
                      ? "/assets/imgs/genealogy.png"
                      : "/assets/imgs/genealogy.png";
        return {
          _id: `${child._id}`,
          name: `${child.fullName}`,
          img: statusdata2,
          attributes: {
            // id: child.id,
            referId: child.referId,
            // upline: child.referBy,
            // createdAt: child.createdAt,
            // rank: statusdata,
          },
          children: generateTreeData(data, child._id),
        };
      }),
      ...(leftPlaceholder
        ? [
            {
              name: "New",
              img: "/assets/imgs/placeholder.png",
              attributes: {},
              children: [],
            },
          ]
        : []),
      ...right.map((child, index) => {
        const status = child.status;
        // console.log(status);/
        const statusdata =
          status == 0
            ? "fc"
            : status == 1
              ? "fr"
              : status == 2
                ? "br"
                : status == 3
                  ? "ct"
                  : status == 4
                    ? "mq"
                    : status == 5
                      ? "duke"
                      : "";
        // console.log(statusdata);
        const statusdata2 =
          status == 0
            ? "/assets/imgs/genealogy.png"
            : status == 1
              ? "/assets/imgs/genealogy.png"
              : status == 2
                ? "/assets/imgs/genealogy.png"
                : status == 3
                  ? "/assets/imgs/genealogy.png"
                  : status == 4
                    ? "/assets/imgs/genealogy.png"
                    : status == 5
                      ? "/assets/imgs/genealogy.png"
                      : "/assets/imgs/genealogy.png";
        return {
          _id: `${child._id}`,
          name: `${child.fullName}`,
          img: statusdata2,
          attributes: {
            // id: child.id,
            referId: child.referId,
            // upline: child.referBy,
            // createdAt: child.createdAt,
            // rank: statusdata,
          },
          children: generateTreeData(data, child._id),
        };
      }),
      ...(rightPlaceholder
        ? [
            {
              name: "New",
              img: "/assets/imgs/placeholder.png",
              attributes: {},
              children: [],
            },
          ]
        : []),
    ];
  };
  const generateTreeData2 = (data, parentId) => {
    // console.log(data);
    const children = data.filter((item) => item.referByuserId == parentId);
    children.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return children.map((child) => {
      const status = child.status;
      // console.log(status);/
      const statusdata =
        status == 0
          ? "/assets/imgs/fc.png"
          : status == 1
            ? "/assets/imgs/fr.png"
            : status == 2
              ? "/assets/imgs/br.png"
              : status == 3
                ? "/assets/imgs/ct.png"
                : status == 4
                  ? "/assets/imgs/mq.png"
                  : status == 5
                    ? "/assets/imgs/duke.png"
                    : "/assets/imgs/duke.png";
      // console.log(statusdata);
      return {
        name: `${child.fullName}`,
        label: child.referId,
        expanded: false,
        type: "person",
        data: { name: `${child.fullName}`, avatar: "walter.jpg" },
        attributes: {
          // id: child.id,
          // referId: child.referId,
          upline: child.referBy,
          // createdAt: child.createdAt,
          rank: statusdata,
        },
        children: generateTreeData(data, child._id),
      };
    });
  };

  const createTreeData = (user) => {
    const status = treeData.status;
    console.log(status);
    const statusdata =
      status == 0
        ? "fc"
        : status == 1
          ? "fr"
          : status == 2
            ? "br"
            : status == 3
              ? "ct"
              : status == 4
                ? "mq"
                : status == 5
                  ? "duke"
                  : "";

    const statusdata2 =
      status == 0
        ? "/assets/imgs/genealogy.png"
        : status == 1
          ? "/assets/imgs/genealogy.png"
          : status == 2
            ? "/assets/imgs/genealogy.png"
            : status == 3
              ? "/assets/imgs/genealogy.png"
              : status == 4
                ? "/assets/imgs/genealogy.png"
                : status == 5
                  ? "/assets/imgs/genealogy.png"
                  : "/assets/imgs/genealogy.png";
    console.log(statusdata);

    const treeData3 = {
      _id: treeData?._id,
      name: treeData?.fullName,
      img: statusdata2,
      attributes: {
        // id: 1,
        referId: treeData.referId,
        // upline: treeData.referBy,
        // createdAt: treeData.createdAt,
        // rank: statusdata,
      },
      children: generateTreeData(
        treeData?.children?.length > 0 ? treeData?.children : [],
        treeData?._id
      ),
    };

    return treeData3;
  };
  const createTreeData2 = (user) => {
    const status = treeData?.status;
    console.log(status);
    const statusdata =
      status == 0
        ? "/assets/imgs/fc.png"
        : status == 1
          ? "/assets/imgs/fr.png"
          : status == 2
            ? "/assets/imgs/br.png"
            : status == 3
              ? "/assets/imgs/ct.png"
              : status == 4
                ? "/assets/imgs/mq.png"
                : status == 5
                  ? "/assets/imgs/duke.png"
                  : "/assets/imgs/duke.png";
    console.log(statusdata);

    const treeData3 = {
      name: treeData?.fullName,
      label: treeData?.referId,
      expanded: true,
      type: "person",
      data: { name: treeData?.fullName, avatar: statusdata },
      attributes: {
        // id: 1,
        referId: treeData?.referId,
        // upline: treeData.referBy,
        // createdAt: treeData.createdAt,
        rank: statusdata,
      },
      children: generateTreeData2(
        treeData?.children?.length > 0 ? treeData?.children : [],
        treeData?._id
      ),
    };

    return treeData3;
  };

  const datavvv = {
    name: "CEO",
    children: [
      {
        name: "Manager 1",
        children: [{ name: "Employee 1" }, { name: "Employee 2" }],
      },
      {
        name: "Manager 2",
        children: [{ name: "Employee 3" }, { name: "Employee 4" }],
      },
    ],
  };
  console.log(createTreeData2());
  const orgChartData = {
    name: "John Doe",
    imgUrl: "https://example.com/john.jpg",
    title: "CEO",
    children: [
      {
        name: "Team A",
        title: "Sales Team",
        children: [
          {
            name: "Jane Smith",
            imgUrl: "https://example.com/jane.jpg",
            title: "VP of Sales",
          },
          {
            name: "Tom Brown",
            imgUrl: "https://example.com/tom.jpg",
            title: "Sales Manager",
          },
          {
            name: "Alice Johnson",
            imgUrl: "https://example.com/alice.jpg",
            title: "Sales Representative",
          },
        ],
      },
      {
        name: "Team B",
        title: "Marketing Team",
        children: [
          {
            name: "Mike Wilson",
            imgUrl: "https://example.com/mike.jpg",
            title: "VP of Marketing",
          },
          {
            name: "Emily Clark",
            imgUrl: "https://example.com/emily.jpg",
            title: "Marketing Manager",
          },
          {
            name: "Chris Lee",
            imgUrl: "https://example.com/chris.jpg",
            title: "Marketing Specialist",
          },
        ],
      },
    ],
  };
  const orgChartDatavv = {
    name: "John Doe",
    children: [
      { name: "Jane Smith" },
      { name: "Mike Wilson" },
      { name: "Emily Clark" },
    ],
  };
  const renderCustomNodeElement = (rd3tProps, state) => {
    // You can access node data using rd3tProps.nodeData
    const { nodeData, nodeDatum, toggleNode } = rd3tProps;
    // console.log(rd3tProps);
    // console.log(nodeDatum);
    // console.log(nodeData);
    // console.log(state);
    // You can also access state if needed (passed as the second argument)
    // const { someStateValue } = state;

    // Return JSX representing the custom node element
    return (
      <MixedNodeElement
        nodeData={nodeDatum}
        triggerNodeToggle={toggleNode}
        handleNodeClick={handleNodeClick}
        foreignObjectProps={{
          width: 170,
          height: 200,
          x: -85,
          y: -60,
        }}
      />
    );
  };
  const handleNodeClick = (nodeData, evt) => {
    console.log("Clicked node:", nodeData);
    if (nodeData) {
      fetchDownlineHierarchy2(nodeData?._id);
    }

    // setClickedNode(nodeData);
  };
  return (
    <div>
      <Modal
        title="Delete Certificate"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Direct Team"}
            subHeading={`Total ${order_list?.totalDocs || 0} Direct Team`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
      </div>

      <div className="tableResponsive mt-10 genology">
        {treeData ? (
          <Tree
            data={createTreeData(treeData)}
            // onClick={handleNodeClick}
            orientation="vertical"
            //   onNodeClick={onNodeClick}
            pathFunc={"step"}
            translate={{ x: 550, y: 80 }}
            renderCustomNodeElement={renderCustomNodeElement}
            nodeLabelComponent={{
              render: <CustomNodeComponent />, // Use the custom node component to render the user image and labels
              foreignObjectWrapper: {
                x: -75,
                y: -50,
              },
            }}
            nodeSvgShape={{
              shape: "none", // Hide the default node shape
            }}
            nodeSize={{ x: 230, y: 150 }} // Adjust the node size to accommodate the image and labels
            collapsible={true}
            defaultCollapsed
            initialDepth={2}
          />
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default GenologyComponent;
