import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
import Boxes3 from "./Boxes3";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { allapiAction } from "../../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../Redux/common/url";
import WelcomeBox from "./WelcomeBox";
import PlanBox from "./PlanBox";

function Deshboard1() {
  const dispatch = useDispatch();
  const [profileModal, setProfileModal] = useState(false);
  const [profileModal1, setProfileModal1] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [userProfile1, setUserProfile1] = useState({});

  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const get_super_profile = useSelector((state) =>
    state?.allapi?.get_super_profile ? state?.allapi?.get_super_profile : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...ad].reverse();

  useEffect(() => {
    setUserProfile(get_profile);

    return () => {};
  }, [get_profile]);

  const profileModalShow = () => setProfileModal(true);
  const profileModalShow1 = () => setProfileModal1(true);
  const profileModalHide = () => setProfileModal(false);
  const profileModalHide1 = () => setProfileModal1(false);

  const router = useNavigate();

  const handleProfileData = (e) => {
    const { name, value } = e.target;
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };
  const handleProfileData1 = (e) => {
    const { name, value } = e.target;
    setUserProfile1({
      ...userProfile1,
      [name]: value,
    });
  };

  async function submitProfileData() {
    if (!userProfile.fullName) {
      toast.error("Please enter full name!");
    } else if (!userProfile.email) {
      toast.error("Please enter email!");
    } else {
      await dispatch(allapiAction.updateUserProfile(userProfile));
      dispatch(allapiAction.getprofile());
      setProfileModal(false);
    }
  }
  async function submitProfileData1() {
    if (!userProfile1.amount) {
      toast.error("Please enter amount");
    } else if (!userProfile1.transationId) {
      toast.error("Please enter utr no.");
    } else {
      await dispatch(allapiAction.addPointreq(userProfile1));
      // dispatch(allapiAction.getprofile());
      setProfileModal1(false);
    }
  }

  useEffect(() => {
    // dispatch(allapiAction.vendordeshBoradData({}));
    // dispatch(allapiAction.categoryWiseSale({}));
    dispatch(allapiAction.deshBoradDataUserData());
    dispatch(allapiAction.getprofile());
    dispatch(allapiAction.getInvoice());
    getSupClubId();
    dispatch(allapiAction.getsliderList({ page: "home" }));
    return () => {};
  }, []);

  const [supDetails, setsupDetails] = useState({});
  console.log(supDetails);

  const getSupClubId = async () => {
    const response = await dispatch(allapiAction.getSuperprofile());
    setsupDetails(response?.data);
  };

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = get_category_sale;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  let pageUrl =
    "https://dhanwan.online/referalsignup?ref=" + get_profile?.referId;
  // let pageUrl =
  //   "https://dhanwan.online/referalsignup?ref=" +
  //   get_profile?.referId +
  //   "&club=left";
  let pageUrl2 =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&club=right";
  let pageUrll =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&clubsuper=left";
  let pageUrl2r =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&clubsuper=right";

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }
  function copy2() {
    const el = document.createElement("input");
    el.value = pageUrl2;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }
  function copysup() {
    const el = document.createElement("input");
    el.value = pageUrll;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }
  function copy2sup() {
    const el = document.createElement("input");
    el.value = pageUrl2r;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }

  return (
    <div>
      <Row justify={"center"}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="  ">
            <div className="mark_bg mt-2">
              <div className="headertext">Latest Notification</div>
              <div className="news">
                <marquee>
                  नोट - आप अपने डैशबोर्ड को लॉगिन करके उसमें इनकम चेक करते रहे
                  आपने जो पैकेज लिया हुआ है उसे पैकेज के अकॉर्डिंग आपको इनकम
                  मिलेगी मतलब की एग्जांपल के तौर पर अगर आपका पैकेज 2070 का है तो
                  आपको तीन गुना आपकी आईडी पर इनकम आएगी जैसे ही यह पैकेज स्टॉप
                  होता है आप तुरंत इसे रिन्यू करवा ले और इनकम को कंटिन्यू करें
                  नहीं तो इनकम आना रुक जाएगी, यह पैकेज के अकॉर्डिंग कंपनी ने
                  कैपिंग डिसाइड कर रखी है, लेकिन अगर आप रिन्यू करते रहेंगे तो
                  आपकी इनकम स्टॉप नहीं होगी आपकी टीम का बिजनेस भी आपको मिलता
                  रहेगा इसलिए अपने डैशबोर्ड को चेक करते रहे इनकम चेक करते रहे.
                </marquee>
              </div>
            </div>
          </div>
          <div className="  ">
            <div className="mark_bg mt-2">
              <div className="headertext">Latest Notification</div>
              <div className="news">
                <marquee>
                  Log in to your dashboard regularly to check your income. The
                  income you receive will be according to the package you have
                  purchased. For example, if your package is worth 2070, you
                  will receive three times that amount as income on your ID. As
                  soon as this package reaches its limit, renew it immediately
                  to continue receiving income; otherwise, your income will
                  stop. The company has set a capping based on the package, but
                  if you keep renewing, your income will not stop, and you will
                  continue to benefit from your team's business as well.
                  Therefore, check your dashboard and monitor your income
                  regularly.
                </marquee>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <Row gutter={[0, 20]}>
              {/* <div class="buttons">
    <button class="btn-hover color-1">BUTTON</button>
    <button class="btn-hover color-2">BUTTON</button>
    <button class="btn-hover color-3">BUTTON</button>
    <button class="btn-hover color-4">BUTTON</button>
    <button class="btn-hover color-5">BUTTON</button>
    <button class="btn-hover color-6">BUTTON</button>
    <button class="btn-hover color-7">BUTTON</button>
    <button class="btn-hover color-8">BUTTON</button>
    <button class="btn-hover color-9">BUTTON</button>
    <button class="btn-hover color-10">BUTTON</button>
    <button class="btn-hover color-11">BUTTON</button>
</div> */}
              <Col lg={24} md={24} sm={24} xs={24}>
                <div className="flex1 p-10 weather_section  b2s m-5 cursor-pointer">
                  <div className="row justify-content-between align-item-center">
                    <div className="col-3 col-md-3 ">
                      <div>
                        <img
                          src="/img/logo.png"
                          style={{ width: "100%" }}
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9">
                      <div className="relative boxHeadingTotal">
                        <div class="row">
                          {/* <div class="col-12 col-md-6">
                            <div class="buttons">
                              <button
                                class="btn-hover color-2"
                                onClick={() => {
                                  router("/accounts/refferal");
                                }}
                              >
                                Join Refferal Program
                              </button>
                            </div>
                          </div> */}
                          <div class="col-12 col-md-12">
                            <div class="buttons">
                              <button
                                class="btn-hover color-2"
                                onClick={() => {
                                  copy();
                                }}
                              >
                                Refer
                              </button>
                            </div>
                          </div>
                          {/* <div class="col-12 col-md-12">
                            <div class="buttons">
                              <button
                                class="btn-hover color-2"
                                onClick={() => {
                                  copy2();
                                }}
                              >
                                Refer for Right Club
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Uid : {get_profile?.referId}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Name : {get_profile?.fullName}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Account Status :{" "}
                          <span
                            className={
                              get_profile?.account_status == "Active"
                                ? "text-success font-weight-bold"
                                : "text-danger font-weight-bold"
                            }
                          >
                            {get_profile?.account_status}
                          </span>
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Phone : {get_profile?.phone}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Wallet : {get_profile?.balance}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Earning : {get_profile?.earningbalance}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Club Earning : {get_profile?.clubPoints}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal bg-primary mb-5">
                        <div className="fw-600 text-white p-3">
                          Active Plan : {get_profile?.package_id?.title}
                        </div>
                      </div>
                      <div class="buttons">
                        <button
                          class="btn-hover color-2"
                          onClick={() => {
                            router("/accounts/package");
                          }}
                        >
                          Buy Plan
                        </button>
                      </div>
                      {/* <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Referral Program : {get_profile?.isrefferal}
                        </div>
                      </div> */}
                      <Button
                        type="primary"
                        title="Update"
                        onClick={profileModalShow}
                      >
                        Update
                      </Button>
                      {/* <Button
                        type="primary"
                        title="Add Wallet"
                        style={{ marginLeft: "10px" }}
                        onClick={profileModalShow1}
                      >
                        Add Wallet
                      </Button> */}
                      <div></div>
                      <Button
                        type="primary"
                        title="Add Wallet"
                        style={{ marginTop: "10px" }}
                        onClick={() => router("/accounts/support-ticket")}
                      >
                        Admin Chat support
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          {Object.keys(get_super_profile).length > 0 && (
            <div className=" p-10">
              <Row>
                {/* {get_profile?.package_id && ( */}
                <Col lg={12} md={24} sm={24} xs={24}>
                  <PlanBox />
                </Col>
                {/* )} */}
                <Col lg={12} md={24} sm={24} xs={24}>
                  <WelcomeBox />
                </Col>
              </Row>
            </div>
          )}
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <Boxes3 />
          </div>
        </Col>
      </Row>

      <Modal show={profileModal} onHide={profileModalHide} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mx-auto">
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">Full Name</label>
                <input
                  name="fullName"
                  className="form-control"
                  placeholder="Enter full Name"
                  type="text"
                  value={userProfile.fullName}
                  onChange={handleProfileData}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={userProfile.email}
                  onChange={handleProfileData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={profileModalHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitProfileData}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={profileModal1} onHide={profileModalHide1} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mx-auto">
            <div className="card-body">
              <div className="mb-3">
                <img
                  src={URL?.API_BASE_URL + get_invoice?.homeLogo}
                  style={{ width: "100%" }}
                />
              </div>
              {get_invoice?.upi && (
                <div className="mb-3">Upi Id : {get_invoice?.upi}</div>
              )}
              {get_invoice?.ac && (
                <div className="mb-3">Ac : {get_invoice?.ac}</div>
              )}
              {get_invoice?.ifsc && (
                <div className="mb-3">IFSC : {get_invoice?.ifsc}</div>
              )}
              {get_invoice?.achname && (
                <div className="mb-3">Ac H. Name : {get_invoice?.achname}</div>
              )}
              <div className="mb-3">
                <label className="form-label">Amount</label>
                <input
                  name="amount"
                  className="form-control"
                  placeholder="Enter Amount"
                  type="text"
                  value={userProfile1.amount}
                  onChange={handleProfileData1}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">UTR No.</label>
                <input
                  name="transationId"
                  className="form-control"
                  placeholder="Enter UTR"
                  type="text"
                  value={userProfile1.transationId}
                  onChange={handleProfileData1}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={profileModalHide1}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitProfileData1}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Deshboard1;
