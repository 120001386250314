import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";

function Earn() {
  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(allapiAction.gethomePro({}));
    // dispatch(allapiAction.getsliderList({ page: "home" }));
    dispatch(allapiAction.getMarqueesList2({ page: "home" }));
  }, []);
  return (
    <div class="earn-section">
      <div class="container">
        <div class="content-section">
          {/* <!-- Text Content --> */}
          <div class="text-content">
            <h1>
              World's #1 <span> Earning </span> Platform
            </h1>
            <p>
              Welcome to DHANWAN, Where you earn with the biggest brands and
              highest profits
            </p>
          </div>

          {/* <!-- GIF Section --> */}
          <div class="gif-container">
            <img src="img/matka.gif" alt="Affiliate Marketing GIF" />
          </div>
        </div>

        <div class="card-container">
          {/* <!-- First Card with Image/GIF --> */}
          <div class="card">
            <div class="card-content">
              <img src="img/new.gif" alt="Image or GIF" />
            </div>
          </div>

          {/* <!-- Second Card with Image/GIF --> */}
          <div class="card">
            <div class="card-content">
              <img src="/img/paisanew.jpg" alt="Image or GIF" />
            </div>
          </div>
        </div>

        {/* <!-- video --> */}
        {/* <div className="video-containerrr">
      <video
        width="1000"
        height="420"
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
      >
        <source
          src="/video/wel_v3.mp4"
          type="video/mp4"
        />
        <source
          src="/video/wel_v3.webm"
          type="video/webm"
        />
        Your browser does not support the video tag.
      </video>
      </div> */}

        {/* <!-- highlights section --> */}
        {/* <section>
        <div class="heightlight text-center">
            <h1><b>Our Highlights</b>
            </h1>
        </div>
      </section> */}
        {/* <!-- conter cards --> */}

        {/* <div class="counter-section">
  <div class="counter-card">
      <h2>1,000</h2>
      <p>Users</p>
  </div>
  <div class="counter-card">
      <h2>500</h2>
      <p>Projects</p>
  </div>
  <div class="counter-card">
      <h2>250</h2>
      <p>Clients</p>
  </div>
</div> */}
      </div>
      <div className="header-earn">
        {/* Content before waves */}
        {/* <div className="inner-header flex">
  
  <svg
    version="1.1"
    className="logo"
    baseProfile="tiny"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    xmlSpace="preserve"
  >
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M57,283" />
    <g>
      <path
        fill="#fff"
        d="M250.4,0.8C112.7,0.8,1,112.4,1,250.2c0,137.7,111.7,249.4,249.4,249.4c137.7,0,249.4-111.7,249.4-249.4
          C499.8,112.4,388.1,0.8,250.4,0.8z M383.8,326.3c-62,0-101.4-14.1-117.6-46.3c-17.1-34.1-2.3-75.4,13.2-104.1
          c-22.4,3-38.4,9.2-47.8,18.3c-11.2,10.9-13.6,26.7-16.3,45c-3.1,20.8-6.6,44.4-25.3,62.4c-19.8,19.1-51.6,26.9-100.2,24.6l1.8-39.7
          c35.9,1.6,59.7-2.9,70.8-13.6c8.9-8.6,11.1-22.9,13.5-39.6c6.3-42,14.8-99.4,141.4-99.4h41L333,166c-12.6,16-45.4,68.2-31.2,96.2
          c9.2,18.3,41.5,25.6,91.2,24.2l1.1,39.8C390.5,326.2,387.1,326.3,383.8,326.3z"
      />
    </g>
  </svg>
  <h1 className="tiltle-new">Earn Up To 50% Profit on 200+ Brands</h1>
</div> */}

        {/* Waves Container */}
        {/* <div>
  <svg
    className="waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shapeRendering="auto"
  >
    <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g className="parallax">
      <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
      <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
      <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
      <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
    </g>
  </svg>
</div> */}
        {/* Waves end */}
      </div>
      {/* <!--Header ends--> */}

      {/* <!-- how tolearn --> */}
      <div class="newvid mt-5">
        <div class="video-card mt-20">
          <h1>Learn To Earn</h1>
          <div class="video-container">
            <iframe
              src="https://www.youtube.com/embed/OFic2eqWaWc?si=qlgw5EkgzCeH-uLR"
              title="YouTube video player"
              allowfullscreen
            ></iframe>
            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/QO7yd0lNzN8?si=diVzQ0KDwOUj21pr"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe> */}
          </div>
          <a href="#" class="cta-button">
            Learn More
          </a>
        </div>
      </div>
      {news?.length > 0 && (
        <div className="  ">
          <div className="mark_bg mt-2">
            <div className="headertext">Latest Notification</div>
            <div className="news">
              <marquee>
                {news &&
                  news?.map((data, i) => {
                    return <span key={data._id}>{data?.title}</span>;
                  })}
              </marquee>
            </div>
          </div>
        </div>
      )}

      <div>
        <div>
          <img src="/img/dhaneran.jpeg" alt="" />
        </div>
      </div>

      {/* <div class="faq-container mt-50 ">
<h1>Frequently Asked Questions</h1>

<div class="faq-item">
    <h2>What is DHANWAN?</h2>
    <p>DHANWAN is a cashback and affiliate marketing platform that helps users earn money by shopping through affiliate links. You can earn cashback on your purchases from top brands and online stores by using DHANWAN's referral links.</p>
</div>

<div class="faq-item">
    <h2>How do I earn cashback on DHANWAN?</h2>
    <p>To earn cashback, sign up on DHANWAN, browse available deals, click on a deal that interests you, and complete your purchase on the merchant's site. Cashback will be credited to your DHANWAN account.</p>
</div>

<div class="faq-item">
    <h2>Is it free to use DHANWAN?</h2>
    <p>Yes, DHANWAN is completely free to use. There are no hidden charges or fees. You earn cashback for purchases made through the platform at no additional cost.</p>
</div>

<div class="faq-item">
    <h2>How and when will I receive my cashback?</h2>
    <p>Cashback is credited to your DHANWAN account once the merchant confirms the transaction. It can take 7-15 days depending on the merchant's policy.</p>
</div>

<div class="faq-item">
    <h2>Can I refer others to DHANWAN?</h2>
    <p>Yes, you can refer your friends and family using your referral link. When they make their first purchase, you will earn a referral bonus, and they can start earning cashback as well.</p>
</div>

<div class="faq-item">
    <h2>Is my data safe with DHANWAN?</h2>
    <p>Yes, your privacy and security are our priority. DHANWAN uses secure methods to process your data and ensures it is never shared without your consent.</p>
</div>

<div class="faq-item">
    <h2>What if my cashback isn't credited?</h2>
    <p>If your cashback isn't credited, contact DHANWAN's customer support. They will investigate and help you resolve the issue.</p>
</div>

<div class="faq-item">
    <h2>How can I contact DHANWAN support?</h2>
    <p>You can contact customer support via email: <strong>support@DHANWAN.com</strong> or use live chat on the website.</p>
</div>

<div class="faq-item">
    <h2>Are there any conditions for earning cashback?</h2>
    <p>Cashback is subject to merchant terms. Common restrictions include no cashback on gift cards, taxes, or refunds.</p>
</div>

<div class="faq-item">
    <h2>Can I use DHANWAN with other coupon websites?</h2>
    <p>Yes, but make sure the merchant allows stacking cashback from multiple platforms. Always check the terms before using multiple offers.</p>
</div>

<div class="faq-item">
    <h2>How can I withdraw my cashback?</h2>
    <p>Once you reach the minimum payout, you can withdraw via bank transfer, Paytm, or other available options.</p>
</div>
</div> */}
    </div>
  );
}

export default Earn;
