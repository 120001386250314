import React, { useEffect, useLayoutEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
// import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import SvgIcon from "../const/SvgIcon";
// import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
// import { URL } from "../../Redux/common/url";
import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";

// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
// import Image from "next/image";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import CoustomImg from "../../CoustomImg/CoustomImg";
import { URL } from "../../../Redux/common/url";
import TruncateText from "../../TruncateText/TruncateText";
import { toast } from "react-toastify";

function PackageCom() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_package_list ? state?.allapi?.get_package_list : {}
  );
  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  const get_expire_date = useSelector((state) =>
    state?.allapi?.get_expire_date ? state?.allapi?.get_expire_date : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.searchProduct({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getPackageList(page));
      // dispatch(allapiAction.getExpireDate(page));
      dispatch(allapiAction.getprofile({}));
    }

    return () => {};
  }, [location]);
  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    // } else {
    dispatch(allapiAction.getInvoice());

    // }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, []);
  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
      },
      onCancel() {},
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.createPurchase({
        packageId: userData?._id,
        packageName: userData?.name,
        amount: userData?.amount,
        transationId: "free",

        // pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/product?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/seller/product?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [packageDetails, setpackage] = useState({});

  console.log(packageDetails);
  const router = useNavigate();
  const showModal = (e) => {
    setpackage(e);
    setIsModalOpen(true);
  };


  const [userProfile1, setUserProfile1] = useState({});
  const handleProfileData1 = (e) => {
    const { name, value } = e.target;
    setUserProfile1({
      ...userProfile1,
      [name]: value,
    });
  };

  const hendleToBuy = async () => {
    // {
    //   product_id: item?.productId?._id,
    //   color: item?.productcolorId?._id,
    //   size: item?.productsizeId?._id,
    //   item: item?.item,
    // }
 if (!userProfile1.amount) {
      toast.error("Please enter amount");
    } else if (!userProfile1.transationId) {
      toast.error("Please enter utr no.");
    } else {
    dispatch(
      allapiAction.createOrdervc(
        {
          user_name: get_user_profile?.fullName,
          user_email: get_user_profile?.email,
          user_mobile: get_user_profile?.phone,
          utr: userProfile1.transationId,
          paidAmt: userProfile1.amount,

          _id2: packageDetails?._id,
          // delivery_charges: "0",
          // sub_total: totalSubtotalPrice,
          // address_id: obj,
          // payment_mode_name: "wallet",
          // products_ids: commentsData,
        },
        router
      )
    );
    setIsModalOpen(false);

  }
  };

  return (
    <div>
      <Modal
        title="Active"
        open={isModalOpen}
        onOk={hendleToBuy}
        onCancel={handleCancel}
      >


      <div className="card mx-auto ">
          <div className="card-body">
            <div className="card-body">
              <div className="mb-3">
                <img
                  src={URL?.API_BASE_URL + get_invoice?.homeLogo}
                  style={{ width: "100%" }}
                />
              </div>
              {get_invoice?.upi && (
                <div className="mb-3">Upi Id : {get_invoice?.upi}</div>
              )}
              {get_invoice?.ac && (
                <div className="mb-3">Ac : {get_invoice?.ac}</div>
              )}
              {get_invoice?.ifsc && (
                <div className="mb-3">IFSC : {get_invoice?.ifsc}</div>
              )}
              {get_invoice?.achname && (
                <div className="mb-3">Ac H. Name : {get_invoice?.achname}</div>
              )}
              <div className="mb-3">
                <label className="form-label">Amount</label>
                <input
                  name="amount"
                  className="form-control"
                  placeholder="Enter Amount"
                  type="text"
                  value={userProfile1.amount}
                  onChange={handleProfileData1}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">UTR No.</label>
                <input
                  name="transationId"
                  className="form-control"
                  placeholder="Enter UTR"
                  type="text"
                  value={userProfile1.transationId}
                  onChange={handleProfileData1}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Buy"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={7}>Package</Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p>{userData?.name}</p>
            </Col>
            <Col span={7}>Amount</Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p>{userData?.amount}</p>
            </Col>
            <Col span={7}>Valid</Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p>{userData?.monthExpire}</p>
            </Col>
            <Col span={7}>Description</Col>
            <Col span={1}>:</Col>
            <Col span={16}>
              <p>{userData?.description}</p>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Package"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Package`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => navigate("/seller/product/add-product")}
            >
              Add
          
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <div className="container-fluid shewBox ">
          <div className="row ps-section__content mt-10">
            {" "}
            {listdata &&
              listdata?.map((data, i) => {
                console.log(data);
                return (
                  <div className="col-6 col-sm-3" key={i}>
                    <div className="recentlostfound">
                      <div className="ps-product">
                        <div className="ps-product__thumbnail  ps-product__thumbnailms">
                          <Link to={"#"}>
                            <CoustomImg
                              url={URL.API_BASE_URL + data?.featureImage}
                              altkey={data?.title}
                              w1={200}
                              w2={300}
                              h1={200}
                              h2={300}
                            />
                          </Link>
                        </div>
                        <div className="ps-product__container">
                          <div className="ps-product__content">
                            <Link className="ps-product__title" to={"#"}>
                              <TruncateText
                                text={data?.title}
                                maxLength={windowWidth > 600 ? 25 : 15}
                              />
                              {/* {data?.title} */}
                            </Link>

                            {data?.afterdiscountprice && (
                              <p className="ps-product__price sale">
                                ₹{data?.afterdiscountprice}{" "}
                                {/* <del>₹{data?.price} </del> */}
                              </p>
                            )}

                            <div>
                              <button
                                className="filter-button ml-3"
                                onClick={() => showModal(data)}
                              >
                                Active
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* 

        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        /> */}
      </div>
    </div>
  );
}

export default PackageCom;
