import React from "react";

function Terms() {
  return (
    <div>
      <div>
        <img src="/img/ppt15.jpg"></img>
      </div>
    </div>
  );
}

export default Terms;
