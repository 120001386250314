export const LOADING = "LOADING";
export const LOADING_1 = "LOADING_1";
export const LOADING_CHECKOUT = "LOADING_CHECKOUT";
//user constant

export const CREATE_CATEGORIES = "CREATE_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_FILTER = "GET_CATEGORIES_FILTER";
export const GET_SUB_SUB_CATEGORIES = "GET_SUB_SUB_CATEGORIES";
export const GET_SUB_SUB_CATE_LIST = "GET_SUB_SUB_CATE_LIST";
export const GET_SUB_SUB_CATE_LIST_FIL = "GET_SUB_SUB_CATE_LIST_FIL";
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const SHOW_MENU = "SHOW_MENU";
export const GET_SELLER_PRODUCT_LIST = "GET_SELLER_PRODUCT_LIST";
export const GET_SELLER_PRODUCT_LIST_PUBLICE =
  "GET_SELLER_PRODUCT_LIST_PUBLICE";
export const GET_TAGS_PRODUCT_LIST_PUBLICE = "GET_TAGS_PRODUCT_LIST_PUBLICE";
export const GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY =
  "GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY";
export const GET_SELLER_TICKET_LIST = "GET_SELLER_TICKET_LIST";
export const GET_SELLER_TANSACTION = "GET_SELLER_TANSACTION";
export const OTP_BOX = "OTP_BOX";
export const OTP_BOX_FORGOT = "OTP_BOX_FORGOT";
export const GET_BUSS_PRO = "GET_BUSS_PRO";
export const GET_BUSS_PRO_1 = "GET_BUSS_PRO_1";
export const GET_PROFILE = "GET_PROFILE";
export const GET_SUPER_PROFILE = "GET_SUPER_PROFILE";
export const GET_MESSAGE_LISTS = "GET_MESSAGE_LISTS";
export const GET_INVOICE = "GET_INVOICE";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_SELLER_PROFILE = "GET_SELLER_PROFILE";
export const GET_PAGES = "GET_PAGES";
export const GET_WHATSAPP_QU = "GET_WHATSAPP_QU";
export const GET_EMAIL_QU = "GET_EMAIL_QU";
export const PAGE_DETAILS = "PAGE_DETAILS";
export const SEARCH_TAGS = "SEARCH_TAGS";
export const SEARCH_PRODUCT_PUB = "SEARCH_PRODUCT_PUB";
export const GET_FOOTER_DATA_ALL = "GET_FOOTER_DATA_ALL";
export const GET_home_ALL_PRO = "GET_home_ALL_PRO";
export const TOP_VIEW_ALL_TYPE = "TOP_VIEW_ALL_TYPE";
export const SELLER_LIST = "SELLER_LIST";
export const DESHBOARD_STATE = "DESHBOARD_STATE";
export const SLIDER_LIST = "SLIDER_LIST";
export const BLOG_LIST = "BLOGLIST";
export const BLOG_DETAILS = "BLOG_DETAILS";
export const GET_EXPIRE_DATE = "GET_EXPIRE_DATE";
export const home_POPS = "home_POPS";
export const FAQS_LIST = "FAQS_LIST";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const CITY_LIST = "CITY_LIST";
export const USER_PRODUCT_SERACH = "USER_PRODUCT_SERACH";
export const ADD_CART_ITME = "ADD_CART_ITME";
export const USER_PROFILE = "USER_PROFILE";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const ORDER_DETIALS = "ORDER_DETIALS";
export const SELLER_UPDATE_DETAILS = "SELLER_UPDATE_DETAILS";
export const SELLER_DATE_REPORTS = "SELLER_DATE_REPORTS";
export const MY_DIRECT_TEAM = "MY_DIRECT_TEAM";
export const GET_SUB_SUB_CATEGORY_LISTS = "GET_SUB_SUB_CATEGORY_LISTS";
export const MY_INCOME_LIST = "MY_INCOME_LIST";
export const USER_INCOME_DATE = "USER_INCOME_DATE";
export const ALL_TEAM = "ALL_TEAM";
export const WEEK_DATA = "WEEK_DATA";
export const DAY_DATA = "DAY_DATA";
export const TEAM_COUNT = "TEAM_COUNT";
export const CHALLANGE_DATA = "CHALLANGE_DATA";
// seller
export const GET_PRODUCT_LISTS = "GET_PRODUCT_LISTS";
export const GET_LISTING_LISTS = "GET_LISTING_LISTS";
export const GET_LISTING_DETAILS = "GET_LISTING_DETAILS";

export const GET_SELLER_ALL_LIST = "GET_SELLER_ALL_LIST";
export const DESHBOARD = "DESHBOARD";
export const VENDOR_ORDER = "VENDOR_ORDER";
export const LOST_AND_FOUND_PUBLICE_LIST = "LOST_AND_FOUND_PUBLICE_LIST";
export const MESSAGE_PUBLICE_LIST = "MESSAGE_PUBLICE_LIST";
export const GET_VENDOR_PROFILES = "GET_VENDOR_PROFILES";
export const GET_ALL_EDUCATION_CATEGORY = "GET_ALL_EDUCATION_CATEGORY";
export const GET_ALL_EDUCATION_LIST = "GET_ALL_EDUCATION_LIST";
export const GET_MARQUES_LIST = "GET_MARQUES_LIST";
export const GET_USE_LOST_AND_FOUND_LIST = "GET_USE_LOST_AND_FOUND_LIST";
export const GET_USER_CERTIFICITE_LIST = "GET_USER_CERTIFICITE_LIST";
export const GET_STUDENT_VIDEO_LIST = "GET_STUDENT_VIDEO_LIST";
export const GET_OUR_WORK_LIST = "GET_OUR_WORK_LIST";
export const GET_VIDEO_WITH_PAGI_LIST = "GET_VIDEO_WITH_PAGI_LIST";
export const GET_Like_LIST = "GET_Like_LIST";
export const GET_PAYABLE_AMOUNT_LIST = "GET_PAYABLE_AMOUNT_LIST";
export const GET_WITHDRAWAL_REQUEST_LIST = "GET_WITHDRAWAL_REQUEST_LIST";
export const GET_ACCOUNT_PAYMENT = "GET_ACCOUNT_PAYMENT";
export const GET_JOB_PORTAL_ALL = "GET_JOB_PORTAL_ALL";
export const GET_JOB_CATEGORY_PORTAL_ALL = "GET_JOB_CATEGORY_PORTAL_ALL";
export const GET_MAIN_ALL_CATEGORY = "GET_MAIN_ALL_CATEGORY";
export const GET_SUB_CATEGORY_ALL_LISTS = "GET_SUB_CATEGORY_ALL_LISTS";
export const GET_LOST_AND_FOUND_PU_DETAILS = "GET_LOST_AND_FOUND_PU_DETAILS";
export const GET_LOST_AND_FOUND_LIST_DETAILS =
  "GET_LOST_AND_FOUND_LIST_DETAILS";
export const GET_RESUME_USER = "GET_RESUME_USER";
export const GET_USER_APPLIST_JOB_LIST = "GET_USER_APPLIST_JOB_LIST";
export const GET_VENDOR_CREATED_JOBS = "GET_VENDOR_CREATED_JOBS";
export const GET_JOBS_DETAILS = "GET_JOBS_DETAILS";
export const GET_JOBS_DETAILS_RELETED = "GET_JOBS_DETAILS_RELETED";
export const GET_ALL_BRAND = "GET_ALL_BRAND";
export const BLOG = "BLOG";
export const MARRIAGE_LIST = "MARRIAGE_LIST";
export const MARRIAGE_DETAILS = "MARRIAGE_DETAILS";
export const GET_RECENT_BLOG_LIST = "GET_RECENT_BLOG_LIST";
export const GET_BLOG_CATEGOY_LIST = "GET_BLOG_CATEGOY_LIST";
