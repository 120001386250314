import React from "react";
import { GiftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const PlanBox = () => {
  const router = useNavigate();
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //   height: '100vh',
      //   backgroundColor: '#f5f5f5',
    },
    card1: {
      width: "300px",
      padding: "20px",
      textAlign: "center",
      background: "linear-gradient(135deg, #11cb1b 0%, #bb25fc 100%)",
      color: "#fff",
      borderRadius: "12px",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
    },
    card: {
      width: "300px",
      padding: "20px",
      textAlign: "center",
      background: "linear-gradient(135deg, #cb6511 0%, #fc25d7 100%)",
      color: "#fff",
      borderRadius: "12px",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
      position: "relative",
    },

    overPlan: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background: "#ff5c5cb3",
    },
    textNew: {
      fontSize: "28px",
      margin: "110px 0 11px 0",
      color: "#fff",
      transform: "rotate(-40deg)",
      animation: "blink 2s linear infinite",
    },
    "@keyframes blink": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.3,
      },
      "100%": {
        opacity: 1,
      },
    },

    icon: {
      fontSize: "48px",
      color: "#fff",
      marginBottom: "20px",
    },
    title: {
      fontSize: "24px",
      margin: "10px 0",
      fontWeight: "bold",
      color: "#fff",
    },
    text: {
      fontSize: "16px",
      margin: "11px 0",
      color: "#fff",
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#1890ff",
      border: "none",
      borderRadius: "20px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#40a9ff",
    },
  };

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  let pageUrll =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&clubsuper=left";
  let pageUrl2r =
    "https://dhanwan.online/referalsignup?ref=" +
    get_profile?.referId +
    "&clubsuper=right";

  function copysup() {
    const el = document.createElement("input");
    el.value = pageUrll;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }
  function copy2sup() {
    const el = document.createElement("input");
    el.value = pageUrl2r;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }
  const finalin =
    get_profile?.supportTeam == 470
      ? get_profile?.supportTeam * 2.5
      : get_profile?.supportTeam == 970
        ? get_profile?.supportTeam * 3
        : get_profile?.supportTeam == 2070
          ? get_profile?.supportTeam * 4
          : get_profile?.supportTeam * 5;
  console.log(get_profile?.point);
  console.log(finalin);

  return (
    <div style={styles.container}>
      <div style={get_profile?.point >= finalin ? styles.card : styles.card1}>
        <h2 style={styles.title}>Active Plan</h2>
        <GiftOutlined style={styles.icon} />
        <h2 style={styles.title}>{get_profile?.package_id?.title}</h2>

        <p style={styles.text}>Plan Amount : {get_profile?.supportTeam}</p>
        <p style={styles.text}>Plan Income : {get_profile?.point}</p>

        <p style={styles.text}>
          Plan Income Limit :{" "}
          {get_profile?.supportTeam == 470
            ? get_profile?.supportTeam * 2.5
            : get_profile?.supportTeam == 970
              ? get_profile?.supportTeam * 3
              : get_profile?.supportTeam == 2070
                ? get_profile?.supportTeam * 4
                : get_profile?.supportTeam * 5}
        </p>
        {/* <button

        onClick={copysup}
          style={styles.button}
          onMouseOver={(e) =>
            (e.target.style.backgroundColor =
              styles.buttonHover.backgroundColor)
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = styles.button.backgroundColor)
          }
        >
          Refer For Left
        </button>
        <button
         onClick={copy2sup}
          style={styles.button}
          onMouseOver={(e) =>
            (e.target.style.backgroundColor =
              styles.buttonHover.backgroundColor)
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = styles.button.backgroundColor)
          }
        >
          Refer For Right
        </button> */}
        {get_profile?.point >= finalin && (
          <div style={styles?.overPlan}>
            <p style={styles.textNew}>Your Plan Limit Over</p>
            <Button
              type="primary"
              title="Add Wallet"
              style={{ marginTop: "60px" }}
              onClick={() => router("/accounts/update-package")}
            >
              Update Package
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanBox;
